import React, { useContext, useReducer } from "react";
import { T } from "../helpers/translations";
import moment from "moment";
import "moment/locale/sv";
import firebase from "../configs/firebase";
import { getFiles } from "../helpers/drive";
import { AuthContext } from "../App";
import { constants, reducer, initialState } from "../state/create-test";
import Notification from "../helpers/notification";
import { generateCode, generateId } from "../helpers/code";
import TestModules from "./TestModules";
import Anonymize from "./Anonymize";
import StartCode from "./StartCode";
import AddLinks from "./AddLinks";
import Spelling from "./Spelling";
import {
  Button,
  Modal,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Table,
  Tag,
  Spin,
  Form,
} from "antd";
import datePickerLocale from "antd/es/date-picker/locale/sv_SE";
const { Option } = Select;
const { TextArea } = Input;
const db = firebase.firestore();

const CreateTest = () => {
  const { state } = useContext(AuthContext);
  const [tState, dispatch] = useReducer(reducer, initialState);
  const [form] = Form.useForm();

  const saveSpelling = spelling => {
    dispatch({ type: constants.SPELLING, state: spelling });
  };

  const saveAnonymize = anonymize => {
    dispatch({ type: constants.USE_ANONYMIZE, state: anonymize });
  };

  const saveStartCode = check => {
    dispatch({ type: constants.USE_START_CODE, state: check });
  };

  const saveQuestions = questions => {
    dispatch({ type: constants.QUESTIONS, state: questions });
  };

  const saveModules = modules => {
    dispatch({ type: constants.MODULES, state: modules });
  };

  const saveLinks = links => {
    dispatch({ type: constants.LINKS, state: links });
  };

  const getCourses = async () => {
    const response = await window.gapi.client.request({
      path: "https://classroom.googleapis.com/v1/courses",
      method: "GET",
    });

    const { courses } = response.result;
    return courses;
  };

  const getStudents = async course => {
    const response = await window.gapi.client.request({
      path: `https://classroom.googleapis.com/v1/courses/${course}/students`,
      method: "GET",
    });

    const { students } = response.result;
    return students.map(student => ({
      id: student.userId,
      name: student.profile.name.fullName,
      aids: [],
    }));
  };

  const getDriveAssets = async () => {
    const user = await db.collection("users").doc(state.id).get();
    const assetFolderId = user.data().assetId;
    const { files } = await getFiles(assetFolderId);
    return files;
  };

  const handleSelectDriveAsset = value => {
    const assetsToAdd = tState.driveAssets
      .filter(asset => value.includes(asset.id))
      .map(asset => {
        return {
          id: asset.id,
          name: asset.name,
          mimeType: asset.mimeType,
          url: asset.webViewLink,
          thumbnail: asset.thumbnailLink,
        };
      });
    dispatch({ type: constants.SELECTED_DRIVE_ASSETS, state: assetsToAdd });
  };

  const openModal = async () => {
    if (state.type === "google") {
      const courses = await getCourses();
      const driveAssets = await getDriveAssets();
      dispatch({ type: constants.COURSES, state: courses });
      dispatch({ type: constants.DRIVE_ASSETS, state: driveAssets });
    }
    dispatch({ type: constants.IS_VISIBLE, state: true });
  };

  const courseOnChange = async value => {
    dispatch({ type: constants.IS_LOADING_STUDENTS, state: true });
    dispatch({ type: constants.COURSE, state: value });
    dispatch({ type: constants.STUDENTS, state: [] });
    const students = await getStudents(value);
    dispatch({ type: constants.STUDENTS, state: students });
    dispatch({ type: constants.IS_LOADING_STUDENTS, state: false });
  };

  const createCode = (data, docId) => {
    const {
      unlockCode,
      startCode,
      students,
      course,
      name,
      id,
      startTime,
      endTime,
      date,
      driveAssets,
      links,
      spelling,
    } = data;
    db.collection("codes")
      .doc(docId)
      .set({
        unlockCode,
        startCode,
        students,
        course,
        id,
        name,
        teacher: state.id,
        modules: tState.modules,
        questions: tState.questions,
        description: tState.description,
        useStartCode: tState.useStartCode,
        startTime,
        endTime,
        date,
        docId,
        driveAssets,
        links,
        spelling,
      })
      .then(() => {
        dispatch({ type: constants.RESET });
        form.resetFields();
        Notification(
          "success",
          `${T.GENERAL.saved}`,
          `${T.CREATE_TEST.created}`
        );
      })
      .catch(error => {
        Notification(
          "error",
          `${T.GENERAL.error}`,
          `${T.CREATE_TEST.createError}`
        );
        dispatch({ type: constants.IS_LOADING, state: false });
        console.log(error("Error adding document: ", error));
      });
  };

  const updateRecord = (entry, state, type) => {
    let studentAids;
    if (state === "add") {
      studentAids = tState.students.map(s =>
        s.id === entry.id ? { ...s, aids: s.aids.concat(type) } : { ...s }
      );
    } else if (state === "delete") {
      studentAids = tState.students.map(s =>
        s.id === entry.id
          ? { ...s, aids: s.aids.filter(a => a !== type) }
          : { ...s }
      );
    }
    dispatch({ type: constants.STUDENTS, state: studentAids });
  };

  const saveTest = values => {
    dispatch({ type: constants.IS_LOADING, state: true });

    const {
      students,
      course,
      useAnonymize,
      useStartCode,
      selectedDriveAssets,
      links,
      spelling,
      modules,
      questions,
    } = tState;

    if (
      state.type === "google" &&
      (modules.length === 0 || students.length === 0 || course === "")
    ) {
      dispatch({ type: constants.IS_LOADING, state: false });
      Notification("error", `${T.GENERAL.error}`, `${T.CREATE_TEST.formError}`);
      return;
    } else if (state.type === "email" && modules.length === 0) {
      dispatch({ type: constants.IS_LOADING, state: false });
      Notification("error", `${T.GENERAL.error}`, `${T.CREATE_TEST.formError}`);
      return;
    }

    const name = values["name"];
    const date = values["date"].format("YYYY-MM-DD");
    const startTime = values["startTime"].format("HH:mm");
    const endTime = values["endTime"].format("HH:mm");

    const testData = {
      date,
      startTime,
      endTime,
      user: state.id,
      unlockCode: generateCode("numbers"),
      startCode: generateCode("letters"),
      id: generateId(),
      students,
      course,
      name,
      modules,
      submissions: 0,
      useAnonymize,
      useStartCode: state.type === "email" ? true : useStartCode,
      driveAssets: selectedDriveAssets,
      links,
      spelling,
    };

    if (modules.includes("Flerval")) {
      testData.questions = questions;
    }

    db.collection("users")
      .doc(testData.user)
      .collection("tests")
      .add(testData)
      .then(docRef => {
        console.log("Document written with ID: ", docRef.id);
        createCode(testData, docRef.id);
      })
      .catch(error => {
        Notification("error", `${T.GENERAL.error}`, `${T.CREATE_TEST.error}`);
        dispatch({ type: constants.IS_LOADING, state: false });
        console.error("Error adding document: ", error);
      });
  };

  const closeModal = () => {
    dispatch({ type: constants.RESET });
    form.resetFields();
  };

  return (
    <>
      <Button type="primary" onClick={openModal}>
        Skapa ett prov
      </Button>
      <CollectionCreateForm
        form={form}
        visible={tState.isVisible}
        onCancel={closeModal}
        onCreate={saveTest}
        isLoading={tState.isLoading}
        onCourseChange={courseOnChange}
        courses={tState.courses}
        students={tState.students}
        updateRecord={updateRecord}
        saveQuestions={saveQuestions}
        saveAnonymize={saveAnonymize}
        saveSpelling={saveSpelling}
        saveStartCode={saveStartCode}
        saveModules={saveModules}
        saveLinks={saveLinks}
        isLoadingStudents={tState.isLoadingStudents}
        handleSelectDriveAsset={handleSelectDriveAsset}
        driveAssets={tState.driveAssets}
        userType={state.type}
        dispatch={dispatch}
      />
    </>
  );
};

const CollectionCreateForm = ({
  form,
  visible,
  onCancel,
  onCreate,
  isLoading,
  onCourseChange,
  courses,
  students,
  updateRecord,
  saveQuestions,
  saveAnonymize,
  saveSpelling,
  saveStartCode,
  saveModules,
  saveLinks,
  isLoadingStudents,
  handleSelectDriveAsset,
  driveAssets,
  userType,
  dispatch,
}) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tableColumns = [
    {
      title: `${T.CREATE_TEST.name}`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `${T.CREATE_TEST.info}`,
      dataIndex: "aids",
      key: "aids",
      render: aids => (
        <span>
          {aids &&
            aids.map(a => {
              let color;
              if (a === "uppläsning") {
                color = "volcano";
              } else if (a === "rättstavning") {
                color = "blue";
              }
              return (
                <Tag color={color} key={a}>
                  {a.toUpperCase()}
                </Tag>
              );
            })}
        </span>
      ),
    },
    {
      title: `${T.CREATE_TEST.action}`,
      key: "action",
      render: (_text, record) => (
        <span>
          {record.aids.includes("rättstavning") ? (
            <Button
              type="link"
              onClick={() => updateRecord(record, "delete", "rättstavning")}
              style={{ paddingLeft: "0" }}
            >
              {T.CREATE_TEST.removeSpelling}
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => updateRecord(record, "add", "rättstavning")}
              style={{ paddingLeft: "0" }}
            >
              {T.CREATE_TEST.addSpelling}
            </Button>
          )}
          {record.aids.includes("uppläsning") ? (
            <Button
              type="link"
              onClick={() => updateRecord(record, "delete", "uppläsning")}
              style={{ paddingLeft: "0" }}
            >
              {T.CREATE_TEST.removeVoice}
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => updateRecord(record, "add", "uppläsning")}
              style={{ paddingLeft: "0" }}
            >
              {T.CREATE_TEST.addVoice}
            </Button>
          )}
        </span>
      ),
    },
  ];

  return (
    <Modal
      visible={visible}
      title={T.CREATE_TEST.create}
      onCancel={onCancel}
      onOk={onCreate}
      width={800}
      footer={[
        <Button key="back" onClick={onCancel}>
          {T.GENERAL.close}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => {
            form.validateFields().then(values => {
              onCreate(values);
            });
          }}
        >
          {T.CREATE_TEST.create}
        </Button>,
      ]}
      destroyOnClose={true}
    >
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        autoComplete="off"
        hideRequiredMark={true}
      >
        <Form.Item
          name="name"
          label="Namn"
          rules={[
            {
              required: true,
              message: `${T.CREATE_TEST.testNamePlaceholder}`,
            },
          ]}
        >
          <Input placeholder={T.CREATE_TEST.testNamePlaceholder} />
        </Form.Item>
        <Form.Item label={T.CREATE_TEST.testDescription}>
          <TextArea
            rows={3}
            onBlur={e =>
              dispatch({ type: constants.DESCRIPTION, state: e.target.value })
            }
            placeholder={T.CREATE_TEST.testDescriptionPlaceholder}
          ></TextArea>
        </Form.Item>
        {userType === "google" && (
          <Form.Item label={T.CREATE_TEST.assets}>
            {!driveAssets && (
              <Select
                placeholder={T.CREATE_TEST.loading}
                style={{ width: 200 }}
                loading
                disabled
              ></Select>
            )}
            {driveAssets && driveAssets.length > 0 && (
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={T.CREATE_TEST.chooiceAssets}
                onChange={handleSelectDriveAsset}
              >
                {driveAssets.map(asset => (
                  <Option key={asset.id}>{asset.name}</Option>
                ))}
              </Select>
            )}
            {driveAssets && driveAssets.length === 0 && (
              <Select
                placeholder={T.CREATE_TEST.noAssets}
                style={{ width: 200 }}
                disabled
              ></Select>
            )}
          </Form.Item>
        )}
        <Form.Item label={T.CREATE_TEST.links}>
          <AddLinks saveLinks={saveLinks} />
        </Form.Item>
        <Form.Item
          label="Datum"
          name="date"
          initialValue={moment(
            new Date().toISOString().split("T")[0],
            "YYYY-MM-DD"
          )}
          rules={[
            {
              type: "object",
              required: true,
              message: `${T.CREATE_TEST.chooseDate}`,
            },
          ]}
        >
          <DatePicker locale={datePickerLocale} />
        </Form.Item>
        <Form.Item
          label={T.CREATE_TEST.startTime}
          name="startTime"
          initialValue={moment("08:00", "HH:mm")}
          rules={[
            {
              type: "object",
              required: true,
              message: `${T.CREATE_TEST.chooseStartTime}`,
            },
          ]}
        >
          <TimePicker format="HH:mm" />
        </Form.Item>
        <Form.Item
          label={T.CREATE_TEST.endTime}
          name="endTime"
          initialValue={moment("09:00", "HH:mm")}
          rules={[
            {
              type: "object",
              required: true,
              message: `${T.CREATE_TEST.chooseEndTime}`,
            },
          ]}
        >
          <TimePicker format="HH:mm" />
        </Form.Item>
        <Form.Item label={T.CREATE_TEST.modules}>
          <TestModules
            saveQuestions={saveQuestions}
            saveModules={saveModules}
          ></TestModules>
        </Form.Item>
        <Form.Item label={T.CREATE_TEST.anonymize}>
          <Anonymize saveAnonymize={saveAnonymize}></Anonymize>
        </Form.Item>
        {userType === "google" && (
          <Form.Item label={T.CREATE_TEST.requireStartCode}>
            <StartCode saveStartCode={saveStartCode}></StartCode>
          </Form.Item>
        )}
        {userType === "email" && (
          <Form.Item label={T.CREATE_TEST.allowSpelling}>
            <Spelling saveSpelling={saveSpelling}></Spelling>
          </Form.Item>
        )}
        {userType === "google" && (
          <Form.Item label={T.CREATE_TEST.class}>
            {courses.length > 0 ? (
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder={T.CREATE_TEST.chooseClass}
                optionFilterProp="children"
                onChange={onCourseChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {courses.map(course => (
                  <Option key={course.id} value={course.id}>
                    {course.name}
                  </Option>
                ))}
              </Select>
            ) : (
              <Select
                defaultValue={T.CREATE_TEST.chooseClass}
                style={{ width: 200 }}
                disabled
                loading
              ></Select>
            )}
          </Form.Item>
        )}
        {userType === "google" && isLoadingStudents && (
          <div
            style={{
              height: "50px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Spin />
          </div>
        )}
        {students.length > 0 && (
          <Table
            columns={tableColumns}
            dataSource={students}
            pagination={false}
            rowKey="id"
          />
        )}
      </Form>
    </Modal>
  );
};

export default CreateTest;
