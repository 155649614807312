export const gapiConfig = {
  client_id:
    "192886930161-2sbso7292vo7simcegh5istn8joc8vmd.apps.googleusercontent.com",
  api_key: "AIzaSyCItZpE4axpitziaWeHKlkdkybQMGwpgqs",
  scope:
    "https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/documents",
  discovery_docs: [
    "https://classroom.googleapis.com/$discovery/rest?version=v1",
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
    "https://docs.googleapis.com/$discovery/rest?version=v1"
  ]
};
