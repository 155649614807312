import React, { useEffect, useRef, useContext, useState } from "react";
import { AuthContext } from "../App";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../style/quill-override.css";
import { Layout } from "antd";
const { Content } = Layout;

const Essay = ({ students, saveEssay, words, letters, text, spelling }) => {
  const { state } = useContext(AuthContext);
  const quillRef = useRef(null);
  const [copy, hasCopied] = useState(false);

  useEffect(() => {
    if (students.length > 0) {
      const student = students.find(s => s.id === state.id);
      if (student.aids.includes("rättstavning")) {
        quillRef.current.editingArea.spellcheck = true;
      } else {
        quillRef.current.editingArea.spellcheck = false;
      }
    } else if (spelling) {
      quillRef.current.editingArea.spellcheck = true;
    } else {
      quillRef.current.editingArea.spellcheck = false;
    }

    const copyListener = () => {
      hasCopied(true);
    };

    const cutListener = () => {
      hasCopied(true);
    };

    const pasteListener = e => {
      if (!copy) {
        e.preventDefault();
      }
    };

    window.addEventListener("copy", copyListener);
    window.addEventListener("paste", pasteListener);
    window.addEventListener("cut", cutListener);

    return () => {
      window.removeEventListener("copy", copyListener);
      window.removeEventListener("paste", pasteListener);
      window.removeEventListener("cut", cutListener);
    };
  }, [copy, spelling, state.id, students]);

  const handleChange = text => {
    saveEssay(text);
    getLetters(text);
    getWords(text);
  };

  const getLetters = text => {
    let htmlText = text;
    htmlText = htmlText.replace(/<[^>]*>/g, " ");
    htmlText = htmlText.replace(/\s+/g, " ");
    htmlText = htmlText.trim();
    const num = htmlText.length;
    letters(num);
  };

  const getWords = text => {
    let htmlText = text;
    htmlText = htmlText.replace(/<[^>]*>/g, " ");
    htmlText = htmlText.replace(/\s+/g, " ");
    htmlText = htmlText.trim();
    const num = htmlText.split(" ").length;
    words(num);
  };

  return (
    <Layout className="layout" style={{ marginBottom: "10px" }}>
      <Content>
        <ReactQuill
          theme="snow"
          value={text}
          placeholder="Det var en gång..."
          modules={{
            toolbar: [
              [{ header: [1, 2] }],
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],
          }}
          formats={["header", "bold", "italic", "underline", "list", "bullet"]}
          onChange={handleChange}
          ref={quillRef}
        />
      </Content>
    </Layout>
  );
};

export default Essay;
