import React, { useState } from "react";
import { T } from "../helpers/translations";
import { Button, Input } from "antd";

const AddLinks = (props) => {
  const [links, setLinks] = useState([]);

  const addLink = () => {
    const link = {
      id: links.length + 1,
      title: null,
      url: null,
    };
    setLinks(links.concat(link));
  };

  const handleLinkChange = (e, l, oKey) => {
    const updateLinks = links.map((o) =>
      o.id === l.id ? { ...o, [oKey]: e.target.value } : { ...o }
    );
    setLinks(updateLinks);
  };

  const handleBlur = () => {
    const filterLinks = links.filter((l) => l.title && l.url);
    props.saveLinks(filterLinks);
  };

  return (
    <>
      {links.map((l) => (
        <div key={l.id} style={{ marginBottom: "10px" }}>
          <Input
            placeholder={T.LINKS.title}
            onChange={(e) => handleLinkChange(e, l, "title")}
            onBlur={handleBlur}
          ></Input>
          <Input
            placeholder={T.LINKS.description}
            onChange={(e) => handleLinkChange(e, l, "url")}
            onBlur={handleBlur}
          ></Input>
        </div>
      ))}
      <Button type="default" onClick={addLink} style={{ display: "block" }}>
        {T.LINKS.cta}
      </Button>
    </>
  );
};

export default AddLinks;
