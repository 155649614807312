import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tag, Radio } from "antd";

const MultipleChoiceAnswersTag = ({answers}) => {
  const correct = filterCorrectAnswers(answers);
  return (
    <Tag key="multiplechoice" color="blue">
      {`${correct.length} / ${answers.length}`}
    </Tag>
  );
};

const MultipleChoiceAnswers = ({ answers, questions }) => {
  const answersArr = Object.values(answers);
  return <>
    {questions.map((q, i) => {
      return (
        <div key={q.id} style={{marginBottom: '20px'}}>
          <h4>{q.question}</h4>
          <Radio.Group
            disabled
            defaultValue={Object.values(answersArr[i])[0]}
          >
            <Radio.Button value="1">{q[1]}</Radio.Button>
            <Radio.Button value="2">{q[2]}</Radio.Button>
            <Radio.Button value="3">{q[3]}</Radio.Button>
          </Radio.Group>
          {Object.values(answersArr[i])[0] === "1" ? (
            <span>
              <CheckCircleOutlined
                style={{
                  fontSize: "20px",
                  color: "green",
                  marginLeft: "10px"
                }} />
            </span>
          ) : (
            <span>
              <CloseCircleOutlined
                style={{
                  fontSize: "20px",
                  color: "red",
                  marginLeft: "10px"
                }} />
            </span>
          )}
        </div>
      );
    })}
  </>;
};

const filterCorrectAnswers = answers =>
  answers.filter(a => Object.values(a)[0] === "1");

export { MultipleChoiceAnswersTag, MultipleChoiceAnswers };
