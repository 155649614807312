import React from "react";
import { T } from "../helpers/translations";
import { Layout, Result } from "antd";
const { Content } = Layout;

const StudentSuccess = () => {
  return (
    <Layout className="layout">
      <Content style={{ padding: "20px" }}>
        <div style={{ background: "#fff", padding: 24, minHeight: 280 }}>
          <Result
            status="success"
            title={T.STUDENT_SUCCESS.title}
            subTitle={T.STUDENT_SUCCESS.paragraph}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default StudentSuccess;
