import * as jsPDF from "jspdf";

const createFolder = async (name, parent) => {
  const request = await window.gapi.client.drive.files
    .create({
      name: name,
      parents: parent ? [parent] : [],
      mimeType: "application/vnd.google-apps.folder"
    })
    .then(
      response => {
        const { status, result } = response;
        switch (status) {
          case 200:
            return result;
          default:
            console.error("Execute error", status);
            return;
        }
      },
      err => {
        console.error("Execute error", err);
      }
    );

  return request;
};

const setPublicFolder = async folder => {
  const request = await window.gapi.client.drive.permissions
    .create({
      fileId: folder,
      resource: {
        role: "reader",
        type: "anyone"
      }
    })
    .then(
      response => {
        const { status, result } = response;
        switch (status) {
          case 200:
            return result;
          default:
            console.error("Execute error", status);
            return;
        }
      },
      err => {
        console.error("Execute error", err);
      }
    );

  return request;
};

const getFiles = async folderId => {
  /*
    get folders on drive
    mimeType = 'application/vnd.google-apps.folder'
    */

  /*
    get files in folder id
    '1U3JovJwF5N7avcsl7s_FFJS_NiltKlFa' in parents
    */

  // two different ways of getting file list, first is prefered, using rest api v3 doc.
  // https://developers.google.com/drive/api/v3/reference/files/list

  const request = await window.gapi.client.drive.files
    .list({
      q: `"${folderId}" in parents and trashed=false`,
      fields: "*"
    })
    .then(
      response => {
        const { status, result } = response;
        switch (status) {
          case 200:
            return result;
          default:
            console.error("Execute error", status);
            return;
        }
      },
      err => {
        console.error("Execute error", err);
        return;
      }
    );

  return request;
};

const renameFile = async (id, name) => {
  // Chain promise, add deleteFile in case 200... return
  const request = await window.gapi.client.drive.files
    .copy({
      fileId: id,
      resource: {
        name: name
      }
    })
    .then(
      response => {
        const { status, result } = response;
        switch (status) {
          case 200:
            return result;
          default:
            console.error("Execute error", status);
            return;
        }
      },
      err => {
        console.error("Execute error", err);
        return;
      }
    );
  return request;
};

const deleteFile = async id => {
  const request = await window.gapi.client.drive.files
    .delete({
      fileId: id
    })
    .then(
      response => {
        const { status, result } = response;
        switch (status) {
          case 204:
            return result;
          default:
            console.error("Execute error", status);
            return;
        }
      },
      err => {
        console.error("Execute error", err);
        return;
      }
    );
  return request;
};

const exportToDrive = async (
  type,
  accessToken,
  submissions,
  folderId,
  anonymize
) => {
  if (type === "docs") {
    const resultsPromises = submissions.map(async s => {
      return await createDoc(accessToken, s, folderId, anonymize);
    });
    const exported = await Promise.all(resultsPromises);
    return exported;
  } else if (type === "pdf") {
    const resultsPromises = submissions.map(async s => {
      return await createPdf(accessToken, s, folderId, anonymize);
    });
    const exported = await Promise.all(resultsPromises);
    return exported;
  }
};

const createDoc = async (accessToken, submission, folderId, anonymize) => {
  const htmlContent = anonymize
    ? `<html><header>Okänd - ${submission.numLettersWords}</header><hr><body>${submission.essay}</body></html>`
    : `<html><header>${submission.name} - ${submission.numLettersWords}</header><hr><body>${submission.essay}</body></html>`;
  let file = new Blob([htmlContent], { type: "text/html" });
  let metadata = {
    name: anonymize ? "Okänd" : submission.name,
    mimeType: "application/vnd.google-apps.document",
    parents: [folderId]
  };

  let form = new FormData();
  form.append(
    "metadata",
    new Blob([JSON.stringify(metadata)], { type: "application/json" })
  );
  form.append("file", file);

  const request = await fetch(
    "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id",
    {
      method: "POST",
      headers: new Headers({ Authorization: "Bearer " + accessToken }),
      body: form
    }
  ).then(status => {
    if (status.ok) {
      console.log("docs uploaded", status);
      return status.ok;
    } else {
      console.log("docs upload error", status);
      return status;
    }
  });

  return request;
};

// TODO: html2canvas
// https://www.quora.com/What-is-the-best-way-to-convert-HTML-to-a-PDF-in-React-JS
const createPdf = async (accessToken, submission, folderId, anonymize) => {
  let pdf = new jsPDF("p", "pt", "a4");
  if (anonymize) {
    pdf.fromHTML(
      `<header>Okänd - ${submission.numLettersWords}</header>${submission.essay}`,
      20,
      20,
      { width: 550 }
    );
  } else {
    pdf.fromHTML(
      `<header>${submission.name} - ${submission.numLettersWords}</header>${submission.essay}`,
      20,
      20,
      { width: 550 }
    );
  }
  let blob = pdf.output("blob");
  let file = new Blob([blob], { type: "text/pdf" });
  let metadata = {
    name: anonymize ? "Okänd" : submission.name,
    mimeType: "application/pdf",
    parents: [folderId]
  };

  var form = new FormData();
  form.append(
    "metadata",
    new Blob([JSON.stringify(metadata)], { type: "application/json" })
  );
  form.append("file", file);

  const request = fetch(
    "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id",
    {
      method: "POST",
      headers: new Headers({ Authorization: "Bearer " + accessToken }),
      body: form
    }
  ).then(status => {
    if (status.ok) {
      console.log("pdf uploaded", status);
      return status.ok;
    } else {
      console.log("pdf upload error", status);
      return status;
    }
  });

  return request;
};

export {
  createFolder,
  getFiles,
  renameFile,
  deleteFile,
  setPublicFolder,
  exportToDrive
};
