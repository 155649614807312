import React from "react";
import Slider from "react-slick";
import YouTube from "react-youtube";
import Bottom from "./Bottom";
import { T } from "../helpers/translations";
import Icon, {
  CheckCircleTwoTone,
  GoogleOutlined,
  MailTwoTone,
} from "@ant-design/icons";
import { Layout, Typography, Divider, Row, Col, Card } from "antd";
import { ReactComponent as CoffeCupSvg } from "../assets/cup.svg";
import { ReactComponent as GraduationCapSvg } from "../assets/graduationcap.svg";
import { ReactComponent as PadLockSvg } from "../assets/padlock.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/carousel-override.css";
const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

const Index = () => (
  <Layout className="layout">
    <Content style={{ padding: "20px" }}>
      <div
        style={{
          background: "#fff",
          padding: 24,
          minHeight: 280,
          textAlign: "center",
        }}
      >
        <Title level={2} style={{ padding: "20px 0 5px" }}>
          <Icon component={PadLockSvg} style={{ marginRight: "8px" }} />
          {T.INDEX.header}
        </Title>
        <Paragraph>
          <Text style={{ fontSize: "16px" }}>
            {T.INDEX.subHeader1} <Text code>{T.INDEX.subHeader2}</Text>{" "}
            {T.INDEX.subHeader3} ( <Icon component={CoffeCupSvg} /> ){" "}
            {T.INDEX.subHeader4} ( <Icon component={GraduationCapSvg} /> )
          </Text>
        </Paragraph>
        <Divider style={{ marginTop: "40px" }} />
        <div style={{ background: "#ECECEC" }}>
          <Row gutter={[40, 10]} style={{ maxWidth: "1200px", margin: "auto" }}>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              style={{ padding: "16px" }}
            >
              <Card
                title={
                  <span style={{ textAlign: "center" }}>
                    <Icon
                      component={CoffeCupSvg}
                      style={{ display: "block", fontSize: "40px" }}
                    />
                  </span>
                }
                bordered={true}
                style={{ textAlign: "left" }}
              >
                <Paragraph>
                  <Text>
                    <CheckCircleTwoTone
                      twoToneColor="#c41d7f"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    {T.INDEX.teacher1}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text>
                    <CheckCircleTwoTone
                      twoToneColor="#c41d7f"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    {T.INDEX.teacher2}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text>
                    <CheckCircleTwoTone
                      twoToneColor="#c41d7f"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    {T.INDEX.teacher3}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text>
                    <GoogleOutlined
                      style={{
                        fontSize: "16px",
                        marginRight: "5px",
                        color: "#c41d7f",
                      }}
                    />
                    {T.INDEX.teacher4}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text>
                    <GoogleOutlined
                      style={{
                        fontSize: "16px",
                        marginRight: "5px",
                        color: "#c41d7f",
                      }}
                    />
                    {T.INDEX.teacher5}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text type="secondary">{T.INDEX.teacher6}</Text>
                </Paragraph>
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              style={{ padding: "16px" }}
            >
              <Card
                title={
                  <span style={{ textAlign: "center" }}>
                    <Icon
                      component={GraduationCapSvg}
                      style={{ display: "block", fontSize: "40px" }}
                    />
                  </span>
                }
                bordered={true}
                style={{ textAlign: "left" }}
              >
                <Paragraph>
                  <Text>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    {T.INDEX.student1}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    {T.INDEX.student2}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    {T.INDEX.student3}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    {T.INDEX.student4}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    {T.INDEX.student5}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text type="secondary">{T.INDEX.student6}</Text>
                </Paragraph>
              </Card>
            </Col>
          </Row>
        </div>
        <div
          style={{
            background: "#ECECEC",
            padding: "40px",
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          <Slider
            className="youtube-carousel"
            dots={true}
            speed={800}
            infinite={false}
            style={{ maxWidth: "1200px", margin: "auto" }}
          >
            <Row gutter={[40, 10]}>
              <Col span={24}>
                <YouTube videoId="a1h0c3jvEvo" opts={{ width: "100%" }} />
              </Col>
            </Row>
            <Row gutter={[40, 10]}>
              <Col span={24}>
                <YouTube videoId="3UoxOIrCuE0" opts={{ width: "100%" }} />
              </Col>
            </Row>
          </Slider>
        </div>
        <div
          style={{
            background: "#ECECEC",
            padding: "30px",
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          <MailTwoTone spin style={{ marginRight: "10px", fontSize: "16px" }} />
          <Text style={{ fontSize: "16px" }}>
            {T.INDEX.footer}
            <a href="mailto:info@afyrat.se" style={{ marginLeft: "5px" }}>
              {T.INDEX.email}
            </a>
          </Text>
        </div>
      </div>
    </Content>
    <Bottom />
  </Layout>
);

export default Index;
