import React, { useState } from "react";
import { T } from "../helpers/translations";
import { Modal, Button } from "antd";

const SubmitModal = ({ visible, onCancel, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    onSubmit();
  };

  return (
    <Modal
      visible={visible}
      title={T.STUDENT_SUBMIT_MODAL.title}
      width={800}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {T.GENERAL.decline}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
        >
          {T.GENERAL.confirm}
        </Button>,
      ]}
    >
      <span>{T.STUDENT_SUBMIT_MODAL.paragraph}</span>
    </Modal>
  );
};

export default SubmitModal;
