import React, { useContext } from "react";
import { AuthContext } from "../App";
import firebase from "../configs/firebase";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { T } from "../helpers/translations";
import Icon from "@ant-design/icons";
import { Layout, Menu, Button } from "antd";
import { ReactComponent as CoffeCupSvg } from "../assets/cup.svg";
import { ReactComponent as GraduationCapSvg } from "../assets/graduationcap.svg";
import LogoType from "../assets/logo.png";

const { Header } = Layout;

const Navigation = () => {
  const location = useLocation();
  const { state } = useContext(AuthContext);

  return (
    <Header style={{ padding: "0 0 0 25px", background: "#22333b" }}>
      <Link to="/">
        <img
          alt="Afyrat logo"
          src={LogoType}
          style={{ float: "left", marginTop: "3px", marginRight: "20px" }}
        />
      </Link>
      <Menu
        theme="light"
        mode="horizontal"
        selectedKeys={location.pathname.split("/")[1]}
        style={{ lineHeight: "64px", display: "flex" }}
      >
        <Menu.Item key="larare">
          <Link to="/larare">
            <Icon component={CoffeCupSvg} />
            {T.GENERAL.teacher}
          </Link>
        </Menu.Item>
        <Menu.Item key="elev">
          <Link to="/elev">
            <Icon component={GraduationCapSvg} />
            {T.GENERAL.student}
          </Link>
        </Menu.Item>
        {state.isAuthenticated && <Logout />}
      </Menu>
    </Header>
  );
};

const Logout = () => {
  const { state, dispatch } = useContext(AuthContext);
  const logout = async () => {
    try {
      await firebase.auth().signOut();
      dispatch({
        type: "LOGOUT",
      });
    } catch (err) {
      window.alert(`${T.AUTHENTICATION.logoutError}`, err.message);
    }
  };

  return (
    <span style={{ marginLeft: "auto", marginRight: "20px" }}>
      {state.name ? <span>{state.name}</span> : <span>{state.user}</span>}
      <Button type="danger" onClick={logout} style={{ marginLeft: "10px" }}>
        Logga ut
      </Button>
    </span>
  );
};

export default Navigation;
