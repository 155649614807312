import React from "react";
import { T } from "../helpers/translations";
import { Checkbox } from "antd";

const StartCode = props => {
  const handleChecked = e => {
    props.saveStartCode(e.target.checked);
  };

  return <Checkbox onChange={handleChecked}>{T.GENERAL.confirm}</Checkbox>;
};

export default StartCode;
