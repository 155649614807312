import React, { useState } from "react";
import { Layout, List, Radio } from "antd";
const { Content } = Layout;

const MultipleChoice = ({ code, saveMultipleChoice, answers }) => {
  const [randomize, setRandomize] = useState([]);

  let radioOrder = (components, i) => {
    if (randomize.length === code.questions.length) {
      return components[randomize[i]];
    } else {
      const randomNumber = Math.floor(Math.random() * components.length);
      const arr = randomize;
      arr.push(randomNumber);
      setRandomize(arr);
      return components[randomNumber];
    }
  };

  const onChange = (e, q) => {
    const pick = e.target.value;
    const a = answers.map(item => {
      if (Object.keys(item)[0] === q.toString()) {
        return {
          [q]: pick
        };
      }
      return {
        ...item
      };
    });

    saveMultipleChoice(a);
  };

  const OrderOne = q => (
    <Radio.Group
      onChange={e => onChange(e, q.id)}
      defaultValue={Object.values(answers[q.i])[0]}
    >
      <Radio.Button value="1" style={{ margin: "0 5px" }}>
        {q["1"]}
      </Radio.Button>
      <Radio.Button value="2" style={{ margin: "0 5px" }}>
        {q["2"]}
      </Radio.Button>
      <Radio.Button value="3" style={{ margin: "0 5px" }}>
        {q["3"]}
      </Radio.Button>
    </Radio.Group>
  );

  const OrderTwo = q => (
    <Radio.Group
      onChange={e => onChange(e, q.id)}
      defaultValue={Object.values(answers[q.i])[0]}
    >
      <Radio.Button value="3" style={{ margin: "0 5px" }}>
        {q["3"]}
      </Radio.Button>
      <Radio.Button value="2" style={{ margin: "0 5px" }}>
        {q["2"]}
      </Radio.Button>
      <Radio.Button value="1" style={{ margin: "0 5px" }}>
        {q["1"]}
      </Radio.Button>
    </Radio.Group>
  );

  const OrderThree = q => (
    <Radio.Group
      onChange={e => onChange(e, q.id)}
      defaultValue={Object.values(answers[q.i])[0]}
    >
      <Radio.Button value="3" style={{ margin: "0 5px" }}>
        {q["3"]}
      </Radio.Button>
      <Radio.Button value="1" style={{ margin: "0 5px" }}>
        {q["1"]}
      </Radio.Button>
      <Radio.Button value="2" style={{ margin: "0 5px" }}>
        {q["2"]}
      </Radio.Button>
    </Radio.Group>
  );

  const OrderFour = q => (
    <Radio.Group
      onChange={e => onChange(e, q.id)}
      defaultValue={Object.values(answers[q.i])[0]}
    >
      <Radio.Button value="1" style={{ margin: "0 5px" }}>
        {q["1"]}
      </Radio.Button>
      <Radio.Button value="3" style={{ margin: "0 5px" }}>
        {q["3"]}
      </Radio.Button>
      <Radio.Button value="2" style={{ margin: "0 5px" }}>
        {q["2"]}
      </Radio.Button>
    </Radio.Group>
  );

  const OrderFive = q => (
    <Radio.Group
      onChange={e => onChange(e, q.id)}
      defaultValue={Object.values(answers[q.i])[0]}
    >
      <Radio.Button value="2" style={{ margin: "0 5px" }}>
        {q["2"]}
      </Radio.Button>
      <Radio.Button value="1" style={{ margin: "0 5px" }}>
        {q["1"]}
      </Radio.Button>
      <Radio.Button value="3" style={{ margin: "0 5px" }}>
        {q["3"]}
      </Radio.Button>
    </Radio.Group>
  );

  const OrderSix = q => (
    <Radio.Group
      onChange={e => onChange(e, q.id)}
      defaultValue={Object.values(answers[q.i])[0]}
    >
      <Radio.Button value="2">{q["2"]}</Radio.Button>
      <Radio.Button value="3">{q["3"]}</Radio.Button>
      <Radio.Button value="1">{q["1"]}</Radio.Button>
    </Radio.Group>
  );

  const arr = (q, i) => [
    <OrderOne {...q} i={i} />,
    <OrderTwo {...q} i={i} />,
    <OrderThree {...q} i={i} />,
    <OrderFour {...q} i={i} />,
    <OrderFive {...q} i={i} />,
    <OrderSix {...q} i={i} />
  ];

  const ListStyle = i => {
    if (i % 2 === 0) {
      return { background: "#F6F6F6" };
    }
    return {};
  };

  return (
    <>
      <Layout className="layout" style={{ marginBottom: "10px" }}>
        <Content>
          <List
            bordered={true}
            itemLayout="horizontal"
            dataSource={code.questions}
            renderItem={(q, i) => (
              <List.Item style={ListStyle(i)}>
                <List.Item.Meta
                  title={q.question}
                  style={{ marginRight: "50px" }}
                />
                {radioOrder(arr(q, i), i)}
              </List.Item>
            )}
            style={{ padding: "50px" }}
          />
        </Content>
      </Layout>
    </>
  );
};

export default MultipleChoice;
