import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../App";
import firebase from "../configs/firebase";
import { T } from "../helpers/translations";
import moment from "moment";
import "moment/locale/sv";
import { EditOutlined } from '@ant-design/icons';
import { List, Modal, Button, Input, Spin, Empty, Avatar } from "antd";
const db = firebase.firestore();

const ListTests = () => {
  const { state } = useContext(AuthContext);
  const [codes, setCodes] = useState([]);
  const [course, setCourse] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [input, setInput] = useState("");
  const [redirect, setRedirect] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const unsubscribe = db.collection("codes").onSnapshot((querySnapshot) => {
      setIsLoading(true);
      const queryCodes = [];
      querySnapshot.forEach((doc) => {
        const { students, startTime, endTime, date } = doc.data();
        const studentIds = students.map((s) => s.id);
        const dateTime = moment().format("YYYY-MM-DD HH:mm");

        const dateTimeIsNow = moment(dateTime, "YYYY-MM-DD HH:mm").isBetween(
          moment(`${date} ${startTime}`, "YYYY-MM-DD HH:mm"),
          moment(`${date} ${endTime}`, "YYYY-MM-DD HH:mm")
        );

        if (studentIds.includes(state.id) && dateTimeIsNow) {
          queryCodes.push(doc.data());
        }
      });
      if (queryCodes.length > 0) {
        setCodes(queryCodes);
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
      setIsLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [state.id]);

  const handleClick = (c) => {
    setCourse(c);
    if (!c.useStartCode) {
      setRedirect(c.docId);
    } else {
      setVisible(true);
    }
  };

  const setCode = (e) => {
    setInput(e.target.value);
  };

  const handleCode = () => {
    if (input === course.startCode) {
      setRedirect(course.docId);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getDateTime = (item) => {
    return `${item.date} ${item.startTime} - ${item.endTime}`;
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/elev/prov/${redirect}`,
        }}
      />
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          height: "100px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <Empty
        description={<span>{T.AVAILABLE_TESTS.noTests}</span>}
        style={{ marginTop: "20px" }}
      ></Empty>
    );
  }

  return <>
    <Modal
      visible={isVisible}
      title={course.name}
      onOk={handleCode}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {T.GENERAL.close}
        </Button>,
        <Button key="submit" type="primary" onClick={handleCode}>
          {T.GENERAL.start}
        </Button>,
      ]}
    >
      <Input placeholder={T.AVAILABLE_TESTS.startCode} onChange={setCode} />
    </Modal>
    {codes.length > 0 && (
      <List
        itemLayout="horizontal"
        dataSource={codes}
        style={{ cursor: "pointer" }}
        renderItem={(item) => (
          <List.Item onClick={() => handleClick(item)}>
            <List.Item.Meta
              title={<Button type="link">{item.name}</Button>}
              avatar={<Avatar icon={<EditOutlined />} />}
              description={getDateTime(item)}
            />
          </List.Item>
        )}
      />
    )}
  </>;
};

export default ListTests;
