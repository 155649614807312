import React from "react";
import { Tag } from "antd";

const Counter = ({ words, letters }) => (
  <>
    <Tag color="blue" style={{ margin: "0 10px" }}>
      {letters}/{words}
    </Tag>
  </>
);

export default Counter;
