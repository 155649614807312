import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from "react";
import sanitizeHtml from "sanitize-html";
import moment from "moment";
import "moment/locale/sv";
import { exportToDrive, createFolder } from "../helpers/drive";
import { AuthContext } from "../App";
import { constants, reducer, initialState } from "../state/list-tests";
import firebase from "../configs/firebase";
import { Button, Modal, Table, Divider, Tag, Empty } from "antd";
import Notification from "../helpers/notification";
import {
  MultipleChoiceAnswersTag,
  MultipleChoiceAnswers,
} from "./MultipleChoiceAnswers";
import { T } from "../helpers/translations";
const db = firebase.firestore();

const ListTestsContainer = () => {
  const { state } = useContext(AuthContext);
  const [tState, tDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.LISTENER = null;
    const unsubscribe = db
      .collection("users")
      .doc(state.id)
      .collection("tests")
      .orderBy("date", "desc")
      .onSnapshot(querySnapshot => {
        const tests = [];
        querySnapshot.forEach(doc => {
          tests.push({ ...doc.data(), ...{ docId: doc.id } });
        });
        tDispatch({ type: constants.TESTS, state: tests });
      });
    return () => {
      unsubscribe();
    };
  }, [state.id]);

  const openTest = record => {
    if (window.LISTENER) {
      window.LISTENER();
    }
    window.LISTENER = db
      .collection("users")
      .doc(state.id)
      .collection("tests")
      .doc(record.docId)
      .collection("submissions")
      .orderBy("name", "asc")
      .onSnapshot(querySnapshot => {
        const subs = [];
        querySnapshot.forEach(doc => {
          subs.push(doc.data());
        });
        if (subs.length === 0) {
          tDispatch({ type: constants.NO_SUBMISSIONS, state: true });
        } else {
          tDispatch({ type: constants.NO_SUBMISSIONS, state: false });
        }
        tDispatch({ type: constants.ACTIVE_TEST, state: record });
        tDispatch({ type: constants.SUBMISSIONS, state: subs });
      });
  };

  const askToRemoveTest = id => {
    tDispatch({ type: constants.IS_REMOVE_TEST_VISIBLE, state: true });
    tDispatch({ type: constants.TEST_TO_REMOVE, state: id });
  };

  const handleCloseModal = () =>
    tDispatch({ type: constants.IS_REMOVE_TEST_VISIBLE, state: false });

  const handleRemoveTest = () => {
    tDispatch({ type: constants.IS_REMOVING, state: true });
    db.collection("users")
      .doc(state.id)
      .collection("tests")
      .doc(tState.testToRemove)
      .delete()
      .then(() => {
        db.collection("codes")
          .doc(tState.testToRemove)
          .delete()
          .then(() => {
            tDispatch({ type: constants.IS_REMOVING, state: false });
            tDispatch({ type: constants.IS_REMOVE_TEST_VISIBLE, state: false });
            tDispatch({ type: constants.TEST_TO_REMOVE, state: null });
            tDispatch({ type: constants.NO_SUBMISSIONS, state: false });
            tDispatch({ type: constants.SUBMISSIONS, state: [] });
            Notification(
              "success",
              T.LIST_TESTS.removeTestHeader,
              T.LIST_TESTS.removeTestParagraph
            );
          })
          .catch(() => {
            Notification("error", T.GENERAL.error, T.LIST_TESTS.removeTestError);
            tDispatch({ type: constants.IS_REMOVING, state: false });
            tDispatch({ type: constants.IS_REMOVE_TEST_VISIBLE, state: false });
            tDispatch({ type: constants.TEST_TO_REMOVE, state: null });
          });
      })
      .catch(() => {
        Notification("error", T.GENERAL.error, T.LIST_TESTS.removeTestError);
        tDispatch({ type: constants.IS_REMOVING, state: false });
        tDispatch({ type: constants.IS_REMOVE_TEST_VISIBLE, state: true });
        tDispatch({ type: constants.TEST_TO_REMOVE, state: null });
      });
  };

  return (
    <>
      <RemoveModal
        isVisible={tState.isRemoveTestVisible}
        handleCloseModal={handleCloseModal}
        handleRemoveTest={handleRemoveTest}
        isRemoving={tState.isRemoving}
      />
      <Tests
        tests={tState.tests}
        open={openTest}
        askToRemove={askToRemoveTest}
      ></Tests>
      <Selected
        active={tState.activeTest}
        submissions={tState.submissions}
        empty={tState.noSubmissions}
        tDispatch={tDispatch}
      ></Selected>
      <View
        submission={tState.submission}
        isSubmissionVisible={tState.isSubmissionVisible}
        active={tState.activeTest}
        tDispatch={tDispatch}
      ></View>
    </>
  );
};

const RemoveModal = ({
  isVisible,
  isRemoving,
  handleCloseModal,
  handleRemoveTest,
}) => {
  const { state } = useContext(AuthContext);
  return (
    <Modal
      visible={isVisible}
      title={T.LIST_TESTS.removeTestQuestion}
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal}>
          {T.GENERAL.decline}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleRemoveTest}
          loading={isRemoving}
        >
          {T.LIST_TESTS.removeTestButton}
        </Button>,
      ]}
    >
      {state.type === "google" ? (
        <span>{T.LIST_TESTS.removeTestInformation}</span>
      ) : (
        <span>{T.LIST_TESTS.removeTestInformationEmail}</span>
      )}
    </Modal>
  );
};

const Tests = ({ tests, open, askToRemove }) => {
  const { state, dispatch } = useContext(AuthContext);
  const [isExporting, setExporting] = useState(false);

  const removeAnonymize = id => {
    db.collection("users")
      .doc(state.id)
      .collection("tests")
      .doc(id)
      .update({ useAnonymize: false })
      .then(() => {
        dispatch({ type: constants.SUBMISSIONS, state: [] });
        Notification(
          "success",
          T.GENERAL.success,
          T.LIST_TESTS.anonymizeRemoved
        );
      })
      .catch(() => {
        Notification(
          "error",
          T.GENERAL.error,
          T.LIST_TESTS.anonymizedRemoveError
        );
      });
  };

  const extend = test => {
    const time = moment(test.endTime, "HH:mm")
      .add(15, "minutes")
      .format("HH:mm");

    db.collection("users")
      .doc(state.id)
      .collection("tests")
      .doc(test.docId)
      .update({ endTime: time })
      .then(() => {
        db.collection("codes")
          .doc(test.docId)
          .update({ endTime: time })
          .then(() => {
            Notification("success", T.LIST_TESTS.time, T.LIST_TESTS.extendedTest);
          })
          .catch(() => {
            Notification(
              "error",
              T.GENERAL.error,
              T.LIST_TESTS.extendedTestError
            );
          });
      })
      .catch(() => {
        Notification("error", T.GENERAL.error, T.LIST_TESTS.extendedTestError);
      });
  };

  const exportDrive = async test => {
    setExporting(true);

    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope(
      "https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/drive.file"
    );
    firebase.auth().useDeviceLanguage();
    const refresh = await firebase.auth().signInWithPopup(provider);

    window.gapi.client.setToken({
      access_token: refresh.credential.accessToken,
    });
    dispatch({
      type: "REFRESH",
      payload: {
        token: refresh.credential.accessToken,
      },
    });

    const user = await db.collection("users").doc(state.id).get();

    const submissions = await db
      .collection("users")
      .doc(state.id)
      .collection("tests")
      .doc(test.docId)
      .collection("submissions")
      .get();

    const docs = submissions.docs.map(d => d.data());
    const testFolder = await createFolder(test.name, user.data().driveId);
    if (testFolder.id) {
      const docsExport = await exportToDrive(
        "docs",
        state.token,
        docs,
        testFolder.id,
        test.useAnonymize
      );
      const pdfExport = await exportToDrive(
        "pdf",
        state.token,
        docs,
        testFolder.id,
        test.useAnonymize
      );

      const isOk =
        pdfExport.every(r => r === true) && docsExport.every(r => r === true);
      setExporting(false);

      if (isOk) {
        Notification("success", T.GENERAL.success, T.LIST_TESTS.exportedToDrive);
      } else {
        Notification(
          "error",
          T.GENERAL.error,
          T.LIST_TESTS.exportedToDriveError
        );
      }
    } else {
      Notification("error", T.GENERAL.error, T.LIST_TESTS.exportedToDriveError);
    }
  };

  const tableColumns = [
    {
      title: T.LIST_TESTS.date,
      dataIndex: "date",
      key: "date",
    },
    {
      title: T.LIST_TESTS.time,
      dataIndex: "startTime",
      key: "startTime",
      render: (_text, record) => (
        <span>
          {record.startTime} - {record.endTime}
          <Button
            type="default"
            onClick={() => extend(record)}
            style={{ marginLeft: "10px" }}
          >
            {T.LIST_TESTS.addFifteenMin}
          </Button>
        </span>
      ),
    },
    {
      title: T.LIST_TESTS.name,
      dataIndex: "name",
      key: "name",
    },
    {
      title: T.LIST_TESTS.unlockCode,
      dataIndex: "unlockCode",
      key: "unlockCode",
    },
    {
      title: T.LIST_TESTS.startCode,
      dataIndex: "startCode",
      key: "startCode",
      render: (_text, record) => (
        <span>{record.useStartCode && <span>{record.startCode}</span>}</span>
      ),
    },
    {
      title: T.LIST_TESTS.numSubmissions,
      dataIndex: "submissions",
      key: "submissions",
      render: (_text, record) => (
        <span>
          {state.type === "google" ? (
            <span>{`${record.submissions} / ${record.students.length}`}</span>
          ) : (
            <span>{record.submissions}</span>
          )}
        </span>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_text, record) => (
        <span>
          {record.useAnonymize && (
            <span>
              <Button type="link" onClick={() => removeAnonymize(record.docId)}>
                {T.LIST_TESTS.unAnonymize}
              </Button>
              <Divider type="vertical" />
            </span>
          )}
          <Button type="link" onClick={() => open(record)}>
            {T.LIST_TESTS.showSubmissions}
          </Button>
          <Divider type="vertical" />
          <Button type="link" onClick={() => askToRemove(record.docId)}>
            {T.GENERAL.remove}
          </Button>
        </span>
      ),
    },
    {
      title: "",
      key: "google",
      render: (_text, record) => (
        <span>
          {state.type === "google" && (
            <Button
              type="default"
              onClick={() => exportDrive(record)}
              loading={isExporting}
            >
              {T.LIST_TESTS.export}
            </Button>
          )}
        </span>
      ),
    },
  ];

  if (!tests) {
    return <></>;
  }

  if (tests.length === 0) {
    return (
      <Empty
        description={<span>{T.LIST_TESTS.noTests}</span>}
        style={{ marginTop: "20px" }}
      ></Empty>
    );
  }

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={tests}
        rowKey="id"
        pagination={false}
        style={{ marginTop: "20px" }}
      />
    </>
  );
};

const Selected = ({ submissions, empty, active, tDispatch }) => {
  const tableColumns = [
    {
      title: T.LIST_TESTS.name,
      dataIndex: "name",
      key: "name",
      render: text => (
        <span>
          {active.useAnonymize ? (
            <span>{T.LIST_TESTS.unknown}</span>
          ) : (
            <span>{text}</span>
          )}
        </span>
      ),
    },
    {
      title: T.LIST_TESTS.time,
      dataIndex: "time",
      key: "time",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: status => (
        <span>
          <Tag
            color={status === "inprogress" ? "orange" : "green"}
            key={status}
          >
            {status === "inprogress"
              ? T.LIST_TESTS.inProgress.toUpperCase()
              : T.LIST_TESTS.submitted.toUpperCase()}
          </Tag>
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "numLettersWords",
      key: "numLettersWords",
      render: lw => (
        <span>
          {lw && (
            <Tag key={lw} color="blue">
              {lw}
            </Tag>
          )}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "multiplechoice",
      key: "multiplechoice",
      render: mc => (
        <span>{mc && <MultipleChoiceAnswersTag answers={mc} />}</span>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_text, record) => (
        <span>
          <Button type="link" onClick={() => openSubmission(record)}>
            {T.GENERAL.open}
          </Button>
        </span>
      ),
    },
  ];

  const openSubmission = submission => {
    tDispatch({ type: constants.SUBMISSION, state: submission });
    tDispatch({ type: constants.IS_SUBMISSION_VISIBLE, state: true });
  };

  if (empty) {
    return (
      <Empty
        description={<span>{T.LIST_TESTS.noSubmissions}</span>}
        style={{ marginTop: "20px" }}
      ></Empty>
    );
  }

  return (
    <>
      {submissions.length > 0 && (
        <Table
          columns={tableColumns}
          dataSource={submissions}
          pagination={false}
          rowKey="id"
          style={{ marginTop: "20px" }}
        />
      )}
    </>
  );
};

const View = ({ submission, isSubmissionVisible, active, tDispatch }) => {
  const essayEl = useRef(null);
  useEffect(() => {
    const selectAllListener = event => {
      if (event.ctrlKey && event.key === "a") {
        event.preventDefault();
        window.getSelection().selectAllChildren(essayEl.current);
      }
    };

    window.addEventListener("keydown", selectAllListener);

    return () => {
      window.removeEventListener("keydown", selectAllListener);
    };
  }, []);

  const handleCloseSubmission = () => {
    tDispatch({ type: constants.IS_SUBMISSION_VISIBLE, state: false });
  };

  const cleanEssay = html => {
    return sanitizeHtml(html, {
      allowedTags: [
        "b",
        "i",
        "p",
        "em",
        "strong",
        "h1",
        "h2",
        "br",
        "li",
        "ul",
        "ol",
      ],
    });
  };

  return (
    <>
      {active && (
        <Modal
          visible={isSubmissionVisible}
          title={active.useAnonymize ? T.LIST_TESTS.unknown : submission.name}
          onCancel={handleCloseSubmission}
          footer={[
            <span key="footer">
              <Tag
                color={submission.status === "inprogress" ? "orange" : "blue"}
              >
                {submission.status === "inprogress"
                  ? T.LIST_TESTS.inProgress.toUpperCase()
                  : T.LIST_TESTS.submitted.toUpperCase()}
              </Tag>
              {submission.numLettersWords && (
                <Tag color="blue">{submission.numLettersWords}</Tag>
              )}
              {submission.multiplechoice && (
                <MultipleChoiceAnswersTag answers={submission.multiplechoice} />
              )}
            </span>,
          ]}
          width={850}
        >
          <>
            <div
              ref={essayEl}
              className="teacher-open-essay"
              dangerouslySetInnerHTML={{ __html: submission.essay ? cleanEssay(submission.essay) : null }}
            ></div>
            {submission.multiplechoice && (
              <MultipleChoiceAnswers
                answers={submission.multiplechoice}
                questions={submission.questions}
              />
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default ListTestsContainer;
