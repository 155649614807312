export const initialState = {
  user: localStorage.getItem("user"),
  token: localStorage.getItem("token"),
  id: localStorage.getItem("id"),
  name: localStorage.getItem("name"),
  type: localStorage.getItem("type"),
  role: localStorage.getItem("role"),
  isAuthenticated: localStorage.getItem("id") ? true : false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_EMAIL":
      localStorage.setItem("user", action.payload.user);
      localStorage.setItem("id", action.payload.id);
      localStorage.setItem("type", action.payload.type);
      localStorage.setItem("role", action.payload.role);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        id: action.payload.id,
        type: action.payload.type,
        role: action.payload.role
      };
    case "LOGIN_GOOGLE":
      localStorage.setItem("user", action.payload.user);
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("id", action.payload.id);
      localStorage.setItem("name", action.payload.name);
      localStorage.setItem("type", action.payload.type);
      localStorage.setItem("role", action.payload.role);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        name: action.payload.name,
        token: action.payload.token,
        id: action.payload.id,
        type: action.payload.type,
        role: action.payload.role
      };
    case "REFRESH":
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        token: action.payload.token
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        user: null,
        name: null,
        token: null,
        id: null,
        type: null,
        role: null,
        isAuthenticated: false
      };
    default:
      return state;
  }
};