import React, { useEffect, useContext, useReducer } from "react";
import moment from "moment";
import "moment/locale/sv";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { AuthContext } from "../App";
import { constants, reducer, initialState } from "../state/test-environment";
import firebase from "../configs/firebase";
import { T } from "../helpers/translations";
import Essay from "./Essay";
import Intro from "./Intro";
import Fullscreen from "./Fullscreen";
import MultipleChoice from "./MultipleChoice";
import Counter from "./Counter";
import OfflineModal from "./OfflineModal";
import Locked from "./Locked";
import SubmitModal from "./SubmitModal";
import { Button } from "antd";
const db = firebase.firestore();

const TestEnvironment = () => {
  const { id } = useParams();
  const location = useLocation();
  let history = useHistory();
  const { state } = useContext(AuthContext);
  const [tState, tDispatch] = useReducer(reducer, initialState);
  const {
    code,
    page,
    isIntroVisible,
    essay,
    multiplechoice,
    endTime,
    autoSave,
    numWords,
    numLetters,
    isOffline,
    isFullscreen,
    windowWidth,
    isLocked,
    areYouSure,
  } = tState;

  const {
    docId,
    modules,
    teacher,
    students,
    name,
    description,
    links,
    questions,
    driveAssets,
    spelling,
    unlockCode,
    startTime,
    date,
  } = code;

  const identifier = location.state
    ? location.state.name.trim().toLowerCase().replace(/\s/g, "") +
      localStorage.getItem("identifier")
    : state.id;

  useEffect(() => {
    async function fetchData() {
      const codeDoc = await db
        .collection("codes")
        .doc(id)
        .get()
        .catch(() => history.push("/elev"));
      const userSubmission = await db
        .collection("users")
        .doc(codeDoc.data().teacher)
        .collection("tests")
        .doc(codeDoc.data().docId)
        .collection("submissions")
        .doc(identifier)
        .get()
        .catch(() => history.push("/elev"));

      if (!userSubmission.exists) {
        const initAnswers = codeDoc.data().questions.map(q => {
          return {
            [q.id]: null,
          };
        });
        tDispatch({
          type: constants.INIT_SUBMISSION,
          state: {
            code: codeDoc.data(),
            page: codeDoc.data().modules[0].toLowerCase(),
            multiplechoice: initAnswers,
          },
        });
      } else {
        if (
          userSubmission.exists &&
          userSubmission.data().status === "inprogress"
        ) {
          tDispatch({
            type: constants.CONTINUE_SUBMISSION,
            state: {
              code: codeDoc.data(),
              page: codeDoc.data().modules[0].toLowerCase(),
              essay: userSubmission.data().essay,
              multiplechoice: userSubmission.data().multiplechoice,
              isLocked: true,
            },
          });
        }
        if (
          userSubmission.exists &&
          userSubmission.data().status === "submitted"
        ) {
          tDispatch({ type: constants.SUCCESS, state: true });
        }
      }
    }

    fetchData();

    const unsubscribe = db
      .collection("codes")
      .doc(id)
      .onSnapshot(doc => {
        tDispatch({ type: constants.END_TIME, state: doc.data().endTime });
      });

    const clockIs = setInterval(() => {
      const dateTime = moment().format("YYYY-MM-DD HH:mm");
      const dateTimeIsNow = moment(dateTime, "YYYY-MM-DD HH:mm").isBetween(
        moment(`${date} ${startTime}`, "YYYY-MM-DD HH:mm"),
        moment(`${date} ${endTime}`, "YYYY-MM-DD HH:mm")
      );
      if (!dateTimeIsNow) {
        tDispatch({ type: constants.AUTO_SAVE, state: "submit" });
      } else {
        tDispatch({ type: constants.AUTO_SAVE, state: "auto" });
      }
    }, 20000);

    const fullscreenchangeListener = () => {
      if (windowWidth === 0) {
        tDispatch({ type: constants.WINDOW_WIDTH, state: window.outerWidth });
      }
    };

    const resizeListener = () => {
      if (windowWidth !== 0) {
        if (window.outerWidth === windowWidth) {
          tDispatch({ type: constants.IS_FULLSCREEN, state: true });
        } else {
          tDispatch({ type: constants.IS_FULLSCREEN, state: false });
        }
      }
    };

    const resizeInterval = setInterval(() => {
      if (windowWidth !== 0) {
        if (window.outerWidth < windowWidth) {
          tDispatch({ type: constants.IS_FULLSCREEN, state: false });
        }
      }
    }, 5000);

    const blurListener = () => {
      tDispatch({ type: constants.IS_LOCKED, state: true });
    };

    window.addEventListener("resize", resizeListener);
    window.addEventListener("fullscreenchange", fullscreenchangeListener);
    window.addEventListener("blur", blurListener);

    return () => {
      unsubscribe();
      window.removeEventListener("resize", resizeListener);
      window.removeEventListener("fullscreenchange", fullscreenchangeListener);
      window.removeEventListener("blur", blurListener);
      window.clearInterval(clockIs);
      window.clearInterval(resizeInterval);
    };
  }, [date, endTime, history, id, identifier, startTime, windowWidth]);

  const saveLocked = () => {
    tDispatch({ type: constants.IS_LOCKED, state: false });
  };

  const saveFullscreen = () => {
    tDispatch({ type: constants.IS_FULLSCREEN, state: true });
  };

  const handleNavigate = page => {
    tDispatch({ type: constants.PAGE, state: page });
  };

  const showIntro = () => {
    tDispatch({ type: constants.IS_INTRO_VISIBLE, state: true });
  };

  const hideIntro = () => {
    tDispatch({ type: constants.IS_INTRO_VISIBLE, state: false });
  };

  const saveEssay = text => {
    tDispatch({ type: constants.ESSAY, state: text });
  };

  const saveNumWords = words => {
    tDispatch({ type: constants.NUM_WORDS, state: words });
  };

  const saveNumLetters = letters => {
    tDispatch({ type: constants.NUM_LETTERS, state: letters });
  };

  const saveMultipleChoice = multiplechoice => {
    tDispatch({ type: constants.MULTIPLECHOICE, state: multiplechoice });
  };

  const handleSubmit = async (status, action) => {
    if (action === "click" && !window.navigator.onLine) {
      tDispatch({ type: constants.IS_OFFLINE, state: true });
    } else if (action === "click" && window.navigator.onLine) {
      tDispatch({ type: constants.IS_OFFLINE, state: false });
    }
    const submission = {
      id: identifier,
      user: state.user ? state.user : identifier,
      name: state.name ? state.name : location.state.name,
      status: status,
      time: moment().format("HH:mm"),
    };

    if (modules.includes("Uppsats")) {
      submission.numLettersWords = `${numLetters} / ${numWords}`;
      submission.essay = essay;
    }

    if (modules.includes("Flerval")) {
      submission.multiplechoice = multiplechoice;
      submission.questions = questions;
    }

    await db
      .collection("users")
      .doc(teacher)
      .collection("tests")
      .doc(docId)
      .collection("submissions")
      .doc(submission.id)
      .set(submission);

    if (status === "submitted") {
      await db
        .collection("users")
        .doc(teacher)
        .collection("tests")
        .doc(docId)
        .update({
          submissions: firebase.firestore.FieldValue.increment(1),
        });
      history.push("/elev/inskickat");
    }
  };

  if (autoSave === "auto") {
    handleSubmit("inprogress", "autoSave");
    tDispatch({ type: constants.AUTO_SAVE, state: "idle" });
  } else if (autoSave === "submit") {
    handleSubmit("submitted", "endTime");
  }

  if (docId && !isFullscreen) {
    return <Fullscreen saveFullscreen={saveFullscreen} />;
  }

  if (docId && isLocked) {
    return <Locked saveLocked={saveLocked} unlock={unlockCode} />;
  }

  return (
    <>
      {docId && (
        <>
          {isOffline && <OfflineModal />}
          <SubmitModal
            visible={areYouSure}
            hide={() =>
              tDispatch({ type: constants.ARE_YOU_SURE, state: false })
            }
            onCancel={() =>
              tDispatch({ type: constants.ARE_YOU_SURE, state: false })
            }
            onSubmit={() => handleSubmit("submitted", "click")}
          />
          <Intro
            navigate={handleNavigate}
            visible={isIntroVisible}
            hide={hideIntro}
            name={name}
            description={description}
            links={links}
            driveAssets={driveAssets}
          />
          {modules.includes("Uppsats") && page === "uppsats" && (
            <>
              <Essay
                students={students}
                saveEssay={saveEssay}
                text={essay}
                letters={saveNumLetters}
                words={saveNumWords}
                spelling={spelling}
              />
              <div style={{ paddingBottom: "10px" }}>
                <Counter letters={numLetters} words={numWords} />
                <Button type="link" onClick={() => showIntro(true)}>
                  {T.TEST_ENVIRONMENT.showInstructions}
                </Button>
                {modules.includes("Flerval") && (
                  <Button
                    type="default"
                    onClick={() => handleNavigate("flerval")}
                  >
                    {T.TEST_ENVIRONMENT.nextPart}
                  </Button>
                )}
                <Button
                  type="primary"
                  onClick={() =>
                    tDispatch({
                      type: constants.ARE_YOU_SURE,
                      state: true,
                    })
                  }
                  style={{ float: "right", marginRight: "10px" }}
                >
                  {T.TEST_ENVIRONMENT.submit}
                </Button>
              </div>
            </>
          )}
          {modules.includes("Flerval") &&
            page === "flerval" &&
            multiplechoice.length > 0 && (
              <>
                <MultipleChoice
                  navigate={handleNavigate}
                  code={code}
                  saveMultipleChoice={saveMultipleChoice}
                  answers={multiplechoice}
                />
                <Button type="link" onClick={() => showIntro(true)}>
                  {T.TEST_ENVIRONMENT.showInstructions}
                </Button>
                {modules.includes("Uppsats") && (
                  <Button
                    type="default"
                    onClick={() => handleNavigate("uppsats")}
                  >
                    {T.TEST_ENVIRONMENT.prevPart}
                  </Button>
                )}
                <Button
                  type="primary"
                  onClick={() =>
                    tDispatch({
                      type: constants.ARE_YOU_SURE,
                      state: true,
                    })
                  }
                  style={{ float: "right", marginRight: "10px" }}
                >
                  {T.TEST_ENVIRONMENT.submit}
                </Button>
              </>
            )}
        </>
      )}
    </>
  );
};

export default TestEnvironment;
