import React from "react";
import { T } from "../helpers/translations";
import { Modal } from "antd";

const Intro = () => {
  return (
    <Modal visible={true} title="Du är offline" footer={[]} width={800}>
      <span>{T.OFFLINE.description}</span>
    </Modal>
  );
};

export default Intro;
