import React from "react";
import { T } from "../helpers/translations";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Result, Button } from "antd";

const Fullscreen = ({ saveFullscreen }) => {
  const toggleFullscreen = () => {
    const root = document.getElementsByTagName("html")[0];
    root.requestFullscreen();
    saveFullscreen();
  };

  return (
    <Result
      title={T.FULLSCREEN.title}
      extra={
        <>
          <Button
            type="primary"
            key="fullscreen"
            onClick={toggleFullscreen}
            style={{ marginBottom: "20px" }}
          >
            {T.FULLSCREEN.cta}
          </Button>
          <p>
            <InfoCircleOutlined style={{ marginRight: "8px" }} />
            {T.FULLSCREEN.description}
          </p>
        </>
      }
    />
  );
};

export default Fullscreen;
