import React from "react";
import { LinkedinOutlined, MailOutlined } from "@ant-design/icons";
import { Layout, Typography, Divider } from "antd";
const { Text } = Typography;
const { Footer } = Layout;

const Bottom = () => (
  <Footer style={{ textAlign: "right" }}>
    <Text type="secondary">afyrat.se</Text>
    <Divider
      type="vertical"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.45)" }}
    />

    <a href="mailto:info@afyrat.se">
      <MailOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
    </a>
    <a
      href="https://www.linkedin.com/in/richonpeers/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginLeft: "5px" }}
    >
      <LinkedinOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
    </a>
  </Footer>
);

export default Bottom;
