export const T = {
  GENERAL: {
    teacher: "Lärare",
    student: "Elev",
    error: "Fel",
    classroom: "Google Classroom",
    drive: "Google Drive",
    back: "Tillbaka",
    confirm: "Ja",
    decline: "Ångra",
    close: "Stäng",
    start: "Starta provet",
    saved: "Sparat",
    success: "Klart",
    remove: "Ta bort",
    open: "Öppna",
  },
  INDEX: {
    header: "Säker provmiljö",
    subHeader1: "Kliv in i ett digitalt klassrum",
    subHeader2: "direkt i webbläsaren",
    subHeader3: "som med en säker provmiljö underlättar för både lärare",
    subHeader4: "och elev",
    teacher1: "Skapa uppsats- och flervalsprov",
    teacher2: "Automatisk rättning av flervalsprov",
    teacher3: "Anonymisera inlämningar",
    teacher4: "Exportera inlämningar till Google Drive",
    teacher5: "Tilldela prov till klasser från Google Classroom",
    teacher6: "... och mycket mer",
    student1: "Behaglig skrivmiljö med fördefinierad layout",
    student2: "Slumpvis ordning av flervalsalternativ",
    student3: "Kontinuerlig sparning (+ offline) av arbetet",
    student4: "Avstängd klippa & klistra in",
    student5: "Påslagen/avstängd rättstavning per elev",
    student6: "... och mycket mer",
    footer: "För mer information och en demonstration:",
    email: "info@afyrat.se",
  },
  AUTHENTICATION: {
    sent: "Skickat",
    successEmail: "Vi har skickat ett e-mail till dig",
    invalidEmail: "Angiven e-mail finns inte",
    invalidCode: "Felaktig kod",
    retryCode: "Skriv in provkoden på nytt",
    incorrectTime: "Inte inom provid",
    incorrectTimeMessage: "Du kan inte skriva det här provet just nu",
    invalidLogin: "Felaktiga inloggningsuppgifter",
    unknownEmail: "Du är inte kund hos oss. Ta kontakt!",
    logoutError: "Problem att logga ut",
    googleLogin: "Logga in med Google",
    forgotPassword: "Glömt lösenord",
    createAccount: "Skapa konto",
    passwordLogin: "Logga in med e-mail",
    noLogin: "Prov utan inloggning",
    enterName: "Skriv ditt namn",
    yourName: "Ditt namn",
    enterStartCode: "Skriv in provets startkod",
    startCode: "Provets startkod",
    start: "Starta provet",
    enterEmail: "Ange din e-mail",
    enterPassword: "Ange ditt lösenord",
    email: "E-mail",
    password: "Lösenord",
    logIn: "Logga in",
    logInAccount: "Logga in med befintligt konto",
    resetPassword: "Återställ lösenord",
  },
  LINKS: {
    title: "Titel på länk",
    description: "Ange länk, ex. http://www.wikipedia.se",
    cta: "Lägg till länk",
  },
  AVAILABLE_TESTS: {
    noTests: "Det finns inga aktuella prov just nu.",
    startCode: "Ange startkod",
  },
  FULLSCREEN: {
    title: "Du behöver aktivera fullskärmsläge för att nå provet",
    description: "Om du lämnar fullskärmsläget kommer ditt prov att låsas.",
    cta: "Aktivera fullskärm",
  },
  LOCKED: {
    title: "Ange upplåsningskod för att fortsätta",
    placeholder: "Upplåsningskod...",
    cta: "Skicka",
    error: "Fel",
    errorDescription: "Fel kod angiven. Försök igen",
  },
  OFFLINE: {
    description:
      "Ditt prov är sparat och när du återfår internet kommer du skickas vidare till nästa sida.",
  },
  STUDENT_SUCCESS: {
    title: "Bra jobbat! Ditt prov är inlämnat!",
    paragraph: "Du kan nu stänga ner webbsidan.",
  },
  STUDENT_SUBMIT_MODAL: {
    title: "Vill du skicka in?",
    paragraph:
      "När du har skickat in kommer du inte kunna fortsätta med din uppgift.",
  },
  TEACHER_PAGE: {
    googleError:
      "Det går inte att nå Googles tjänster just nu. Var vänlig och ladda om sidan.",
  },
  TEST_ENVIRONMENT: {
    showInstructions: "Visa instruktioner",
    nextPart: "Nästa del",
    prevPart: "Föregående del",
    submit: "Lämna in",
  },
  TEST_MODULES: {
    placeholder: "Skriv en fråga...",
    correct: "Rätt svar",
    wrong: "Fel svar",
    addQuestion: "Ny fråga",
  },
  CREATE_TEST: {
    created: "Provet är nu skapat",
    createError: "Det gick inte att skapa provet. Försök igen",
    formError: "Var vänlig och fel i de obligatoriska fälten",
    error: "Det gick inte att skapa provet. Försök igen",
    name: "Namn",
    info: "Info",
    action: "Hjälpmedel",
    removeSpelling: "Ta bort rättstavning",
    addSpelling: "Lägg till rättstavning",
    removeVoice: "Ta bort uppläsning",
    addVoice: "Lägg till uppläsning",
    create: "Skapa prov",
    testName: "Namn på provet",
    testDescription: "Beskrivning",
    testNamePlaceholder: "Ange namn på provet",
    testDescriptionPlaceholder: "Ange en beskrivning",
    assets: "Resurser",
    loading: "Laddar resurser",
    chooiceAssets: "Välj resurser att bifoga",
    noAssets: "Hittar inga resurser",
    links: "Länkar",
    date: "Datum",
    chooseDate: "Välj datum",
    startTime: "Starttid",
    chooseStartTime: "Välj starttid",
    endTime: "Sluttid",
    chooseEndTime: "Välj sluttid",
    modules: "Moduler",
    anonymize: "Anonymisera inlämningar",
    requireStartCode: "Kräv startkod",
    allowSpelling: "Tillåt stavningshjälp",
    class: "Klass",
    chooseClass: "Välj en klass",
  },
  LIST_TESTS: {
    anonymizeRemoved: "Anonymiseringen är borttagen",
    anonymizedRemoveError: "Det gick inte att ta bort anonymiseringen",
    time: "Tid",
    extendedTest: "Provtiden är förlängd",
    extendedTestError: "Det gick inte att förlänga provtiden",
    removeTestHeader: "Borttaget",
    removeTestParagraph: "Provet är nu borttaget",
    removeTestError: "Det gick inte att ta bort provet. Försök igen",
    removeTestQuestion: "Vill du ta bort provet?",
    removeTestButton: "Ta bort provet",
    removeTestInformation: "Provet kommer finnas kvar på Google Drive.",
    removeTestInformationEmail: "Provet kommer att försvinna permanent.",
    exportedToDrive: "Inlämningarna finns nu på Google Drive",
    exportedToDriveError: "Det gick inte att exportera till Google Drive",
    date: "Datum",
    name: "Namn",
    unlockCode: "Upplåsningskod",
    startCode: "Startkod",
    numSubmissions: "Antalet inlämningar",
    unAnonymize: "Avanonyminisera",
    showSubmissions: "Visa inlämningar",
    export: "Exportera",
    noTests: "Du har inga skapade prov",
    noSubmissions: "Det finns inga inlämningar",
    addFifteenMin: "+15 min",
    inProgress: "pågående",
    submitted: "inlämnad",
    unknown: "Okänd",
  },
};
