import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(config);

firebase
  .firestore()
  .enablePersistence()
  .catch(err => {
    if (err.code === "failed-precondition") {
      //alert("Offlineläge stödjs inte i din webbläsare. Var vänlig och byt.");
    } else if (err.code === "unimplemented") {
      //alert("Offlineläge stödjs inte i din webbläsare. Var vänlig och byt.");
    }
  });

export default firebase;
