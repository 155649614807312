import React from "react";
import { T } from "../helpers/translations";
import { Checkbox } from "antd";

const Anonymize = (props) => {
  const handleChecked = (e) => {
    props.saveAnonymize(e.target.checked);
  };

  return <Checkbox onChange={handleChecked}>{T.GENERAL.confirm}</Checkbox>;
};

export default Anonymize;
