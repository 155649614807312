import React, { useState } from "react";
import { T } from "../helpers/translations";
import { Tag, Button, Input } from "antd";
const { CheckableTag } = Tag;
const { TextArea } = Input;
const MODULES = ["Uppsats", "Flerval"];

const TestModules = props => {
  const [selectedTags, setSelectedTags] = useState([]);

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter(t => t !== tag);

    setSelectedTags(nextSelectedTags);
    props.saveModules(nextSelectedTags);
  };

  return (
    <>
      {MODULES.map(tag => (
        <CheckableTag
          key={tag}
          checked={selectedTags.indexOf(tag) > -1}
          onChange={checked => handleChange(tag, checked)}
          style={{ cursor: "pointer", fontSize: "14px", lineHeight: "22px" }}
        >
          {tag}
        </CheckableTag>
      ))}
      {selectedTags.includes("Flerval") && (
        <MultipleChoice saveQuestions={props.saveQuestions} />
      )}
    </>
  );
};

const MultipleChoice = props => {
  const initQuestion = [
    {
      id: 1,
      question: null,
      1: null,
      2: null,
      3: null,
    },
  ];

  const [questions, setQuestions] = useState(initQuestion);

  const addQuestion = () => {
    const numQuestions = questions.length;
    const newQuestion = {
      id: numQuestions + 1,
      question: null,
      1: null,
      2: null,
      3: null,
    };
    setQuestions(questions.concat(newQuestion));
  };

  const handleQuestionChange = (e, q, oKey) => {
    const updateQuestions = questions.map(o =>
      o.id === q.id ? { ...o, [oKey]: e.target.value } : { ...o }
    );

    setQuestions(updateQuestions);
  };

  const handleBlur = () => {
    props.saveQuestions(questions);
  };

  return (
    <>
      {questions.map(q => (
        <div key={q.id} style={{ margin: "20px 0" }}>
          <TextArea
            placeholder={T.TEST_MODULES.placeholder}
            rows={3}
            onChange={e => handleQuestionChange(e, q, "question")}
            onBlur={handleBlur}
          ></TextArea>
          <Input
            placeholder={T.TEST_MODULES.correct}
            onChange={e => handleQuestionChange(e, q, "1")}
            onBlur={handleBlur}
            style={{ border: "2px solid green" }}
          ></Input>
          <Input
            placeholder={T.TEST_MODULES.wrong}
            onChange={e => handleQuestionChange(e, q, "2")}
            onBlur={handleBlur}
          ></Input>
          <Input
            placeholder={T.TEST_MODULES.wrong}
            onChange={e => handleQuestionChange(e, q, "3")}
            onBlur={handleBlur}
          ></Input>
        </div>
      ))}

      <Button type="default" onClick={addQuestion} style={{ display: "block" }}>
        {T.TEST_MODULES.addQuestion}
      </Button>
    </>
  );
};

export default TestModules;
