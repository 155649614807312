const generateCode = type => {
  let code = "";
  let possible = "";
  if (type === "letters") {
    possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  } else {
    possible = "0123456789";
  }
  for (let i = 0; i < 6; i++) {
    code += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return code;
};

const generateId = () => {
  return Math.random().toString(16).slice(2);
};

export { generateCode, generateId };
