import React, { useState } from "react";
import { T } from "../helpers/translations";
import { Result, Button, Input } from "antd";
import Notification from "../helpers/notification";

const Locked = ({ saveLocked, unlock }) => {
  const [input, setInput] = useState("");

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleCheckCode = () => {
    if (unlock === input) {
      saveLocked();
    } else {
      Notification(
        "error",
        `${T.LOCKED.error}`,
        `${T.LOCKED.errorDescription}`
      );
    }
  };

  return (
    <Result
      status="warning"
      title={T.LOCKED.title}
      extra={
        <div key="unlockcode">
          <Input
            placeholder={T.LOCKED.placeholder}
            onChange={handleInputChange}
            key="inputfield"
            style={{ width: 200 }}
          ></Input>
          <Button type="primary" key="unlock" onClick={handleCheckCode}>
            {T.LOCKED.cta}
          </Button>
        </div>
      }
    />
  );
};

export default Locked;
