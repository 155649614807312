import React, { useState } from "react";
import { Modal, Button, Spin } from "antd";

const Intro = ({ visible, hide, name, description, links, driveAssets }) => {
  const Assets = () => {
    const [active, setActive] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const openAsset = asset => {
      const link = asset.thumbnail.split("=s")[0];
      const url =
        process.env.NODE_ENV === "development"
          ? `https://cors-anywhere.herokuapp.com/${link}=s850`
          : `${link}=s850`;

      fetch(url).then(response =>
        response.blob().then(blob => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            setActive(base64data);
            setLoading(false);
          };
        })
      );
    };

    return (
      <div>
        {driveAssets.map(a => (
          <Button key={a.id} type="default" onClick={() => openAsset(a)}>
            {a.name}
          </Button>
        ))}
        {isLoading && (
          <div>
            <Spin />
          </div>
        )}
        {active && (
          <div>
            <img src={active} alt=""></img>
          </div>
        )}
      </div>
    );
  };

  const Links = () => {
    const [active, setActive] = useState(null);

    const openLink = link => {
      const url =
        process.env.NODE_ENV === "development"
          ? `https://cors-anywhere.herokuapp.com/${link}=s850`
          : `${link}=s850`;

      fetch(url).then(response =>
        response.text().then(text => {
          setActive(text);
        })
      );
    };

    return (
      <div>
        {links.map(l => (
          <Button key={l.id} type="default" onClick={() => openLink(l.url)}>
            {l.title}
          </Button>
        ))}
        {active && (
          <div
            dangerouslySetInnerHTML={{ __html: active }}
            style={{ maxHeight: "800px", overflowY: "scroll" }}
          ></div>
        )}
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      title={name}
      onCancel={hide}
      footer={[]}
      width={850}
    >
      <span>{description}</span>
      {driveAssets && driveAssets.length > 0 && <Assets />}
      {links && links.length > 0 && <Links />}
    </Modal>
  );
};

export default Intro;
