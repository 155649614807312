export const constants = {
  REDIRECT: "REDIRECT",
  SUCCESS: "SUCCESS",
  PAGE: "PAGE",
  IS_INTRO_VISIBLE: "IS_INTRO_VISIBLE",
  ESSAY: "ESSAY",
  MULTIPLECHOICE: "MULTIPLECHOICE",
  END_TIME: "END_TIME",
  AUTO_SAVE: "AUTO_SAVE",
  NUM_WORDS: "NUM_WORDS",
  NUM_LETTERS: "NUM_LETTERS",
  IS_OFFLINE: "IS_OFFLINE",
  IS_FULLSCREEN: "IS_FULLSCREEN",
  WINDOW_WIDTH: "WINDOW_WIDTH",
  IS_LOCKED: "IS_LOCKED",
  ARE_YOU_SURE: "ARE_YOU_SURE",
  CODE_PAGE: "CODE_PAGE",
  INIT_SUBMISSION: "INIT_SUBMISSION",
  CONTINUE_SUBMISSION: "CONTINUE_SUBMISSION",
};

export const initialState = {
  redirect: false,
  success: false,
  code: {},
  page: "",
  isIntroVisible: true,
  essay: "",
  multiplechoice: [],
  endTime: "",
  autoSave: "idle",
  numWords: 0,
  numLetters: 0,
  isOffline: false,
  isFullscreen: false,
  windowWidth: 0,
  isLocked: false,
  areYouSure: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case constants.REDIRECT:
      return {
        ...state,
        redirect: action.state,
      };
    case constants.SUCCESS:
      return {
        ...state,
        success: action.state,
      };
    case constants.PAGE:
      return {
        ...state,
        page: action.state,
      };
    case constants.IS_INTRO_VISIBLE:
      return {
        ...state,
        isIntroVisible: action.state,
      };
    case constants.ESSAY:
      return {
        ...state,
        essay: action.state,
      };
    case constants.MULTIPLECHOICE:
      return {
        ...state,
        multiplechoice: action.state,
      };
    case constants.END_TIME:
      return {
        ...state,
        endTime: action.state,
      };
    case constants.AUTO_SAVE:
      return {
        ...state,
        autoSave: action.state,
      };
    case constants.NUM_WORDS:
      return {
        ...state,
        numWords: action.state,
      };
    case constants.NUM_LETTERS:
      return {
        ...state,
        numLetters: action.state,
      };
    case constants.IS_OFFLINE:
      return {
        ...state,
        isOffline: action.state,
      };
    case constants.IS_FULLSCREEN:
      return {
        ...state,
        isFullscreen: action.state,
      };
    case constants.WINDOW_WIDTH:
      return {
        ...state,
        windowWidth: action.state,
      };
    case constants.IS_LOCKED:
      return {
        ...state,
        isLocked: action.state,
      };
    case constants.ARE_YOU_SURE:
      return {
        ...state,
        areYouSure: action.state,
      };
    case constants.INIT_SUBMISSION:
      return {
        ...state,
        code: action.state.code,
        page: action.state.page,
        multiplechoice: action.state.multiplechoice,
      };
    case constants.CONTINUE_SUBMISSION:
      return {
        ...state,
        code: action.state.code,
        page: action.state.page,
        essay: action.state.essay,
        multiplechoice: action.state.multiplechoice,
        isLocked: action.state.isLocked,
      };
    default:
      throw new Error();
  }
}
