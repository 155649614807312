import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { T } from "../helpers/translations";
import moment from "moment";
import "moment/locale/sv";
import { AuthContext } from "../App";
import firebase from "../configs/firebase";
import Notification from "../helpers/notification";
import {
  CheckOutlined,
  GoogleOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row, Spin, Input, Divider, Form } from "antd";
const db = firebase.firestore();

const UserAuth = ({ role }) => {
  const { state, dispatch } = useContext(AuthContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const validUser = async email => {
    const allowedAccounts = await db.collection("users").doc("accounts").get();
    return allowedAccounts.data()["allowed"].includes(email) ? true : false;
  };

  const handleForgotPassword = async values => {
    const { email } = values;
    setSubmitting(true);
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setSubmitting(false);
      setIsForgotPassword(false);
      setIsCreatingAccount(false);
      Notification(
        "success",
        `${T.AUTHENTICATION.sent}`,
        `${T.AUTHENTICATION.successEmail}`
      );
    } catch (err) {
      setSubmitting(false);
      Notification(
        "error",
        `${T.GENERAL.error}`,
        `${T.AUTHENTICATION.invalidEmail}`
      );
    }
  };

  const handleNoLogin = async values => {
    const { startcode, name } = values;
    setSubmitting(true);
    const codes = await db.collection("codes").get();
    const doc = codes.docs
      .map(d => d.data())
      .find(d => d.startCode === startcode.trim());

    if (!doc) {
      setSubmitting(false);
      Notification(
        "error",
        `${T.AUTHENTICATION.invalidCode}`,
        `${T.AUTHENTICATION.retryCode}`
      );
      return;
    }

    const dateTime = moment().format("YYYY-MM-DD HH:mm");
    const dateTimeIsNow = moment(dateTime, "YYYY-MM-DD HH:mm").isBetween(
      moment(`${doc.date} ${doc.startTime}`, "YYYY-MM-DD HH:mm"),
      moment(`${doc.date} ${doc.endTime}`, "YYYY-MM-DD HH:mm")
    );

    localStorage.getItem("identifier") !== null
      ? localStorage.getItem("identifier")
      : localStorage.setItem(
          "identifier",
          Math.floor(Math.random() * 1000) + 1
        );

    if (dateTimeIsNow) {
      setRedirect({
        ...{ docId: doc.docId },
        ...{ name },
      });
    } else {
      setSubmitting(false);
      Notification(
        "error",
        `${T.AUTHENTICATION.incorrectTime}`,
        `${T.AUTHENTICATION.incorrectTimeMessage}`
      );
    }
  };

  const handleCreateAccount = async values => {
    const { email, password } = values;
    setSubmitting(true);
    if (await validUser(email)) {
      try {
        const login = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
        setSubmitting(false);
        setIsForgotPassword(false);
        setIsCreatingAccount(false);
        dispatch({
          type: "LOGIN_EMAIL",
          payload: {
            id: login.user.uid,
            user: login.user.email,
            type: "email",
            role,
          },
        });
      } catch (err) {
        setSubmitting(false);
        Notification(
          "error",
          `${T.GENERAL.error}`,
          `${T.AUTHENTICATION.invalidLogin}`
        );
      }
    } else {
      setSubmitting(false);
      Notification(
        "error",
        `${T.GENERAL.error}`,
        `${T.AUTHENTICATION.unknownEmail}`
      );
    }
  };

  const handleEmailPasswordLogin = async values => {
    const { email, password } = values;
    setSubmitting(true);
    try {
      const login = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      setSubmitting(false);
      dispatch({
        type: "LOGIN_EMAIL",
        payload: {
          id: login.user.uid,
          user: login.user.email,
          type: "email",
          role,
        },
      });
    } catch (err) {
      setSubmitting(false);
      Notification(
        "error",
        `${T.GENERAL.error}`,
        `${T.AUTHENTICATION.invalidLogin}`
      );
    }
  };

  const googlePopupLogin = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope(
        "https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/drive"
      );
      firebase.auth().useDeviceLanguage();
      const login = await firebase.auth().signInWithPopup(provider);
      if ((await validUser(login.user.email)) === false && role === "teacher") {
        Notification(
          "error",
          `${T.GENERAL.error}`,
          `${T.AUTHENTICATION.unknownEmail}`
        );
        setSubmitting(false);
        return;
      } else {
        setSubmitting(false);
        dispatch({
          type: "LOGIN_GOOGLE",
          payload: {
            id: login.additionalUserInfo.profile.id,
            user: login.user.email,
            name: login.additionalUserInfo.profile.name,
            token: login.credential.accessToken,
            type: "google",
            role,
          },
        });
      }
    } catch (err) {
      setSubmitting(false);
    }
  };

  const googleLogin = () => {
    setSubmitting(true);
    googlePopupLogin();
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/elev/prov/${redirect.docId}`,
          state: { name: redirect.name },
        }}
      />
    );
  }

  if (isSubmitting) {
    return (
      <>
        <div
          style={{
            height: "200px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Spin size="large" />
        </div>
      </>
    );
  }

  if (
    (role === "teacher" && !state.isAuthenticated) ||
    (role === "teacher" && state.role === "student" && state.isAuthenticated)
  ) {
    return (
      <div style={{ background: "#ECECEC" }}>
        <Row
          gutter={[40, 10]}
          justify="space-around"
          align="middle"
          style={{ maxWidth: "1000px", margin: "auto" }}
        >
          <Col xs={24} lg={12} style={{ padding: "10px" }}>
            <Card title="Google inloggning" bordered={true}>
              <Button type="primary" onClick={googleLogin}>
                <GoogleOutlined /> {T.AUTHENTICATION.googleLogin}
              </Button>
              <Row style={{ marginTop: "10px" }}>
                <span>
                  <CheckOutlined style={{ color: "#52c41a" }} />{" "}
                  {T.GENERAL.classroom}
                </span>
              </Row>
              <Row>
                <span>
                  <CheckOutlined style={{ color: "#52c41a" }} />{" "}
                  {T.GENERAL.drive}
                </span>
              </Row>
            </Card>
          </Col>
          {isForgotPassword && (
            <Col
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              style={{ padding: "10px" }}
            >
              <Card title={T.AUTHENTICATION.forgotPassword} bordered={true}>
                <ForgotPasswordForm
                  onFinish={handleForgotPassword}
                  setIsForgotPassword={setIsForgotPassword}
                />
              </Card>
            </Col>
          )}
          {!isForgotPassword && isCreatingAccount && (
            <Col
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              style={{ padding: "10px" }}
            >
              <Card title={T.AUTHENTICATION.createAccount} bordered={true}>
                <CreateAccountForm
                  onFinish={handleCreateAccount}
                  setIsCreatingAccount={setIsCreatingAccount}
                />
              </Card>
            </Col>
          )}
          {!isForgotPassword && !isCreatingAccount && (
            <Col
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              style={{ padding: "10px" }}
            >
              <Card title={T.AUTHENTICATION.passwordLogin} bordered={true}>
                <EmailPasswordLoginForm
                  onFinish={handleEmailPasswordLogin}
                  setIsCreatingAccount={setIsCreatingAccount}
                  setIsForgotPassword={setIsForgotPassword}
                />
              </Card>
            </Col>
          )}
        </Row>
      </div>
    );
  }

  if (
    (role === "student" && !state.isAuthenticated) ||
    (role === "student" && state.role === "teacher" && state.isAuthenticated)
  ) {
    return (
      <div style={{ background: "#ECECEC" }}>
        <Row
          gutter={[40, 10]}
          justify="space-around"
          align="middle"
          style={{ maxWidth: "1000px", margin: "auto" }}
        >
          <Col xs={{ span: 24 }} lg={{ span: 12 }} style={{ padding: "10px" }}>
            <Card title="Google inloggning" bordered={true}>
              <Button type="primary" onClick={googleLogin}>
                <GoogleOutlined /> {T.AUTHENTICATION.googleLogin}
              </Button>
              <Row style={{ marginTop: "10px" }}>
                <span>
                  <CheckOutlined style={{ color: "#52c41a" }} />{" "}
                  {T.GENERAL.classroom}
                </span>
              </Row>
              <Row>
                <span>
                  <CheckOutlined style={{ color: "#52c41a" }} />{" "}
                  {T.GENERAL.drive}
                </span>
              </Row>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }} style={{ padding: "10px" }}>
            <Card title={T.AUTHENTICATION.noLogin} bordered={true}>
              <NoLoginForm onFinish={handleNoLogin} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  return <></>;
};

const NoLoginForm = ({ onFinish }) => (
  <Form onFinish={onFinish}>
    <Form.Item
      name="name"
      rules={[
        {
          required: true,
          whitespace: true,
          message: `${T.AUTHENTICATION.enterName}`,
        },
      ]}
    >
      <Input
        prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        placeholder={T.AUTHENTICATION.yourName}
      />
    </Form.Item>
    <Form.Item
      name="startcode"
      rules={[
        {
          required: true,
          message: `${T.AUTHENTICATION.enterStartCode}`,
        },
      ]}
    >
      <Input
        prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        type="text"
        placeholder={T.AUTHENTICATION.startCode}
      />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        {T.AUTHENTICATION.start}
      </Button>
    </Form.Item>
  </Form>
);

const EmailPasswordLoginForm = ({
  onFinish,
  setIsCreatingAccount,
  setIsForgotPassword,
}) => (
  <Form onFinish={onFinish}>
    <Form.Item
      name="email"
      rules={[
        {
          type: "email",
          required: true,
          message: `${T.AUTHENTICATION.enterEmail}`,
        },
      ]}
    >
      <Input
        prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        placeholder={T.AUTHENTICATION.email}
      />
    </Form.Item>
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: `${T.AUTHENTICATION.enterPassword}`,
        },
      ]}
    >
      <Input
        prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        type="password"
        placeholder={T.AUTHENTICATION.password}
      />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        {T.AUTHENTICATION.logIn}
      </Button>
    </Form.Item>
    <div>
      <span>
        <Button
          type="link"
          onClick={() => setIsCreatingAccount(true)}
          style={{ padding: "4px 0px" }}
        >
          {T.AUTHENTICATION.createAccount}
        </Button>
        <Divider type="vertical" />
        <Button
          type="link"
          onClick={() => setIsForgotPassword(true)}
          style={{ padding: "4px 0px" }}
        >
          {T.AUTHENTICATION.forgotPassword}
        </Button>
      </span>
    </div>
  </Form>
);

const CreateAccountForm = ({ onFinish, setIsCreatingAccount }) => (
  <Form onFinish={onFinish}>
    <Form.Item
      name="email"
      rules={[
        {
          type: "email",
          required: true,
          message: `${T.AUTHENTICATION.enterEmail}`,
        },
      ]}
    >
      <Input
        prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        placeholder={T.AUTHENTICATION.email}
      />
    </Form.Item>
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: `${T.AUTHENTICATION.enterPassword}`,
        },
      ]}
    >
      <Input
        prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        type="password"
        placeholder={T.AUTHENTICATION.password}
      />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        {T.AUTHENTICATION.createAccount}
      </Button>
    </Form.Item>
    <div>
      <span>
        <Button
          type="link"
          onClick={() => setIsCreatingAccount(false)}
          style={{ padding: "4px 0px" }}
        >
          {T.AUTHENTICATION.logInAccount}
        </Button>
      </span>
    </div>
  </Form>
);

const ForgotPasswordForm = ({ onFinish, setIsForgotPassword }) => (
  <Form onFinish={onFinish}>
    <Form.Item
      name="email"
      rules={[
        {
          type: "email",
          required: true,
          message: `${T.AUTHENTICATION.enterEmail}`,
        },
      ]}
    >
      <Input
        prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        placeholder={T.AUTHENTICATION.email}
      />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        {T.AUTHENTICATION.resetPassword}
      </Button>
    </Form.Item>
    <div>
      <span>
        <Button
          type="link"
          onClick={() => setIsForgotPassword(false)}
          style={{ padding: "4px 0px" }}
        >
          {T.GENERAL.back}
        </Button>
      </span>
    </div>
  </Form>
);

export default UserAuth;
