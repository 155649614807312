export const constants = {
  IS_VISIBLE: "IS_VISIBLE",
  IS_LOADING: "IS_LOADING",
  COURSES: "COURSES",
  COURSE: "COURSE",
  STUDENTS: "STUDENTS",
  QUESTIONS: "QUESTIONS",
  MODULES: "MODULES",
  DESCRIPTION: "DESCRIPTION",
  IS_LOADING_STUDENTS: "IS_LOADING_STUDENTS",
  USE_ANONYMIZE: "USE_ANONYMIZE",
  USE_START_CODE: "USE_START_CODE",
  DRIVE_ASSETS: "DRIVE_ASSETS",
  SELECTED_DRIVE_ASSETS: "SELECTED_DRIVE_ASSETS",
  LINKS: "LINKS",
  SPELLING: "SPELLING",
  RESET: "RESET",
};

export const initialState = {
  isVisible: false,
  isLoading: false,
  courses: [],
  students: [],
  course: [],
  questions: [],
  modules: [],
  description: [],
  isLoadingStudents: false,
  useAnonymize: false,
  useStartCode: false,
  driveAssets: null,
  selectedDriveAssets: null,
  links: null,
  spelling: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case constants.IS_VISIBLE:
      return {
        ...state,
        isVisible: action.state,
      };
    case constants.IS_LOADING:
      return {
        ...state,
        isLoading: action.state,
      };
    case constants.COURSES:
      return {
        ...state,
        courses: action.state,
      };
    case constants.STUDENTS:
      return {
        ...state,
        students: action.state,
      };
    case constants.COURSE:
      return {
        ...state,
        course: action.state,
      };
    case constants.QUESTIONS:
      return {
        ...state,
        questions: action.state,
      };
    case constants.MODULES:
      return {
        ...state,
        modules: action.state,
      };
    case constants.DESCRIPTION:
      return {
        ...state,
        description: action.state,
      };
    case constants.IS_LOADING_STUDENTS:
      return {
        ...state,
        isLoadingStudents: action.state,
      };
    case constants.USE_ANONYMIZE:
      return {
        ...state,
        useAnonymize: action.state,
      };
    case constants.USE_START_CODE:
      return {
        ...state,
        useStartCode: action.state,
      };
    case constants.DRIVE_ASSETS:
      return {
        ...state,
        driveAssets: action.state,
      };
    case constants.SELECTED_DRIVE_ASSETS:
      return {
        ...state,
        selectedDriveAssets: action.state,
      };
    case constants.LINKS:
      return {
        ...state,
        links: action.state,
      };
    case constants.SPELLING:
      return {
        ...state,
        spelling: action.state,
      };
    case constants.RESET:
      return {
        ...initialState,
      };
    default:
      throw new Error();
  }
}
