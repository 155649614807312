export const constants = {
  TESTS: "TESTS",
  SUBMISSIONS: "SUBMISSIONS",
  SUBMISSION: "SUBMISSION",
  IS_SUBMISSION_VISIBLE: "IS_SUBMISSION_VISIBLE",
  IS_REMOVE_TEST_VISIBLE: "IS_REMOVE_TEST_VISIBLE",
  TEST_TO_REMOVE: "TEST_TO_REMOVE",
  IS_REMOVING: "IS_REMOVING",
  NO_SUBMISSIONS: "NO_SUBMISSIONS",
  ACTIVE_TEST: "ACTIVE_TEST",
};

export const initialState = {
  tests: null,
  submissions: [],
  submission: [],
  isSubmissionVisible: false,
  isRemoveTestVisible: false,
  testToRemove: null,
  isRemoving: false,
  noSubmissions: false,
  activeTest: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case constants.TESTS:
      return {
        ...state,
        tests: action.state,
      };
    case constants.SUBMISSIONS:
      return {
        ...state,
        submissions: action.state,
      };
    case constants.SUBMISSION:
      return {
        ...state,
        submission: action.state,
      };
    case constants.IS_SUBMISSION_VISIBLE:
      return {
        ...state,
        isSubmissionVisible: action.state,
      };
    case constants.IS_REMOVE_TEST_VISIBLE:
      return {
        ...state,
        isRemoveTestVisible: action.state,
      };
    case constants.TEST_TO_REMOVE:
      return {
        ...state,
        testToRemove: action.state,
      };
    case constants.IS_REMOVING:
      return {
        ...state,
        isRemoving: action.state,
      };
    case constants.NO_SUBMISSIONS:
      return {
        ...state,
        noSubmissions: action.state,
      };
    case constants.ACTIVE_TEST:
      return {
        ...state,
        activeTest: action.state,
      };
    default:
      throw new Error();
  }
}
