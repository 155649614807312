import React, { useContext } from "react";
import { AuthContext } from "../App";
import AvailableTests from "./AvailableTests";
import Bottom from "./Bottom";
import UserAuth from "./UserAuth";
import { Layout } from "antd";
const { Content } = Layout;

const Student = () => {
  const { state } = useContext(AuthContext);
  return (
    <>
      <Layout className="layout">
        <Content style={{ padding: "20px" }}>
          <div style={{ background: "#fff", padding: 24, minHeight: 280 }}>
            <UserAuth role="student" />
            {state.id && state.role === 'student' && <StudentContainer />}
          </div>
        </Content>
        <Bottom />
      </Layout>
    </>
  );
};

const StudentContainer = () => {
  return (
    <>
      <AvailableTests />
    </>
  );
};

export default Student;
