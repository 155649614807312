import React, { useReducer, createContext } from "react";
import { Route, useLocation } from "react-router-dom";
import { reducer, initialState } from "./state/general";
import Navigation from "./components/Navigation";
import IndexPage from "./components/IndexPage";
import StudentPage from "./components/StudentPage";
import TestEnvironment from "./components/TestEnvironment";
import TeacherPage from "./components/TeacherPage";
import StudentSuccess from "./components/StudentSuccess";
import './style/antd.css';
export const AuthContext = createContext();

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      <div>
        {!location.pathname.includes("/elev/prov") && <Navigation />}
        <Route path="/elev/prov/:id">
          <TestEnvironment />
        </Route>
        <Route exact path="/elev/inskickat">
          <StudentSuccess />
        </Route>
        <Route exact path="/elev">
          <StudentPage />
        </Route>
        <Route exact path="/larare">
          <TeacherPage />
        </Route>
        <Route exact path="/">
          <IndexPage />
        </Route>
      </div>
    </AuthContext.Provider>
  );
};

export default App;
