import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import { gapiConfig } from "../configs/gapi";
import firebase from "../configs/firebase";
import { createFolder, setPublicFolder } from "../helpers/drive";
import { T } from "../helpers/translations";
import CreateTest from "./CreateTest";
import ListTests from "./ListTests";
import Bottom from "./Bottom";
import UserAuth from "./UserAuth";
import { Layout, Spin } from "antd";
const { Content } = Layout;
const db = firebase.firestore();

const Teacher = () => {
  const { state } = useContext(AuthContext);
  return (
    <>
      <Layout className="layout">
        <Content style={{ padding: "20px" }}>
          <div style={{ background: "#fff", padding: 24, minHeight: 280 }}>
            <UserAuth role="teacher" />
            {state.id && state.role === "teacher" && <TeacherContainer />}
          </div>
        </Content>
        <Bottom />
      </Layout>
    </>
  );
};

const TeacherContainer = () => {
  const { state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (state.type === "google" && (!window.gapi || !window.gapi.client)) {
      setIsLoading(true);
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://apis.google.com/js/api.js";
      document.head.appendChild(script);
      script.onload = _e => {
        window.gapi.load("client:auth2", () => {
          window.gapi.auth2.init(gapiConfig).then(
            () => {
              window.gapi.client.load("drive", "v3", () => checkDrive());
            },
            _error => {
              window.alert(`${T.TEACHER_PAGE.googleError}`);
              setIsLoading(false);
            }
          );
        });
      };
    }

    const fetchDrive = async () => {
      const driveFolder = await createFolder("Afyrat");
      const assetFolder = await createFolder("Resurser", driveFolder.id);
      setPublicFolder(driveFolder.id, assetFolder.id);
      db.collection("users")
        .doc(state.id)
        .set({ driveId: driveFolder.id, assetId: assetFolder.id });
    };

    const checkDrive = () => {
      db.collection("users")
        .doc(state.id)
        .get()
        .then(doc => {
          if (!doc.data() || !doc.data().driveId || !doc.data().assetId) {
            fetchDrive();
          }
        });
      setIsLoading(false);
    };
  }, [state.id, state.type]);

  if (isLoading) {
    return (
      <div
        style={{
          height: "200px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <CreateTest />
      <ListTests />
    </>
  );
};

export default Teacher;
